import {GetEventData, SliderItem} from "react-compound-slider";
import React, {useEffect, useRef, useState} from "react";
import {SliderToolTip} from "./SliderToolTip";
import {DateRange} from "../../@types/DateRange";
import {SPREAD_THRESHOLD} from "./DateFilterSlider";

interface TrackProps {
    source: SliderItem;
    target: SliderItem;
    range: DateRange;
    domain: DateRange;
    updateTimeRange: (dateRange: DateRange) => void;
    checkAutoAdvance: (handleId: string) => void;
    getEventData: GetEventData;
    handleId: string;
}

const Track: React.FunctionComponent<TrackProps> = ({
  source,
  target,
  range,
  domain,
  updateTimeRange,
  checkAutoAdvance,
  getEventData,
  handleId,
}: TrackProps) => {
  const trackRef = useRef<HTMLDivElement>(null);

  // use to track the space between the mouse pointer and the slider handle for track move and keep interval
  const leftInterval = useRef<number>(0);
  const rightInterval = useRef<number>(0);

  const [mouseDown, setMouseDown] = useState(false);
  const [mouseHover, setMouseHover] = useState(false);

  useEffect(() => {
    function onMouseDown(e: MouseEvent) {
      const clickValue = getEventData(e).value;
      leftInterval.current = clickValue - (range.start.getTime() / 1000);
      rightInterval.current = (range.end.getTime() / 1000) - clickValue;
      setMouseDown(true);

       // Checking if autoAdv is turned on.
      checkAutoAdvance(target.id);

      e.stopPropagation();
      e.preventDefault();
    }

    const track = trackRef.current;

    track?.focus();
    track?.addEventListener("mousedown", onMouseDown);

    return () => track?.removeEventListener("mousedown", onMouseDown);
  }, [checkAutoAdvance, getEventData, range, target.id]);

  const onMouseMove = (e: React.MouseEvent) => {
    if (mouseDown) {
      const mouseValue = getEventData(e).value;
      let newStart = new Date(range.start);
      newStart.setTime((mouseValue - leftInterval.current) * 1000);
      // handle if the newStart is before the domain start, we don't want to go past.
      let newEnd = new Date(range.end);
      newEnd.setTime((mouseValue + rightInterval.current) * 1000);
      if (newStart < domain.start) {
          newStart = domain.start;
          // Make sure to keep the interval constant, even if the user keeps dragging to the start
          newEnd.setTime(domain.start.getTime() + ((leftInterval.current + rightInterval.current) * 1000));
      }
      if (newEnd > domain.end) {
          newEnd = domain.end;
          newStart.setTime(domain.end.getTime() - ((leftInterval.current + rightInterval.current) * 1000));
      }
      // handle if the newEnd is before domain, don't want to go earlier
      updateTimeRange(new DateRange(newStart, newEnd));
    }
  };

  const onMouseEnter = () => {
    // If mouseDown gets left on when the user moves mouse away from the slider it
    // gets reset upon mouse enter. Seems to be working..
    if (mouseDown) {
      setMouseDown(false);
    }
    setMouseHover(true);
  };

  const onMouseUp = (e: React.MouseEvent) => {
    setMouseDown(false);
    e.stopPropagation();
    e.preventDefault();
  };

  let percentages = [source.percent, target.percent];
  const spreadThresholdPercentage = SPREAD_THRESHOLD * 100;
  if (handleId) {
    if (handleId === "$$-0") {
      percentages = [Math.min((percentages[1] - spreadThresholdPercentage), source.percent), target.percent];
    } else {
      percentages = [source.percent, Math.max((percentages[0] + spreadThresholdPercentage), target.percent)];
    }
    if (percentages[0] < 0) {
      percentages[0] = 0;
      if (percentages[1] < 5) percentages[1] = 5;
    }
    if (percentages[1] > 100) {
      percentages[1] = 100;
      if (percentages[0] > 95) percentages[0] = 95;
    }
  }

  return (
    <>
      <div
        className={`slider-tooltip ${mouseHover ? "" : "hss-ms-invisible"}`}
        style={{marginLeft: `${percentages[0] - 10}%`}}
      >
        <SliderToolTip rangeStart={range.start} rangeEnd={range.end} />
      </div>
      <div
        ref={trackRef}
        className="slider-track"
        style={{left: `${percentages[0]}%`, width: `${percentages[1] - percentages[0]}%`}}
        onMouseMove={onMouseMove}
        onMouseEnter={onMouseEnter}
        onMouseUp={onMouseUp}
        onMouseLeave={() => setMouseHover(false)}
      />
    </>
  );
};

export default Track;
