import {connect} from "react-redux";
import {hideToast} from "../actions/ToastActions";
import {IApplicationState} from "../Store";

import Toasts, {StateProps, DispatchProps} from "../components/Toasts";

const mapStateToProps = (state: IApplicationState): StateProps => ({
        toasts: state.ui.toasts,
    });

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    hideToast: (toast) => dispatch(hideToast(toast)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
