import React from "react";
import {DateRange} from "../../@types/DateRange";

interface ITickArgs {
    ticksCount: number;
    range: DateRange;
    domain: DateRange;
    onRangeUpdate: Function;
    autoAdvanceTime: boolean;
    setAdvTimeInterval: Function;
}
export function Tick({ticksCount, range, domain, onRangeUpdate, autoAdvanceTime, setAdvTimeInterval}: ITickArgs) {
    const domainStartTimestamp = domain.start.getTime();
    const domainEndTimestamp = domain.end.getTime();
    const domainSpan = domainEndTimestamp - domainStartTimestamp;

    const sliderRangeUpdate = (e: any) => {
        const rangeStartTimestamp = range.start.getTime();
        const rangeEndTimestamp = range.end.getTime();
        const rangeSpan = rangeEndTimestamp - rangeStartTimestamp;

        const currentTargetRect = e.currentTarget.getBoundingClientRect();
        let offsetX = (e.clientX - currentTargetRect.left) / currentTargetRect.width;
        if (offsetX < 0.01) {
          offsetX = 0.01;
        }
        if (offsetX > 1) {
          offsetX = 1;
        }
        const offsetTimestamp = domainStartTimestamp + Math.floor(offsetX * domainSpan);

        const newTimestamps = [0, 0];

        if (offsetTimestamp < rangeStartTimestamp) {
          newTimestamps[0] = offsetTimestamp;
          newTimestamps[1] = offsetTimestamp + rangeSpan;
        } else if (offsetTimestamp > rangeEndTimestamp) {
          newTimestamps[0] = offsetTimestamp - rangeSpan;
          newTimestamps[1] = offsetTimestamp;
        }

        if (newTimestamps[0] !== 0 && newTimestamps[1] !== 0) {
          const newDates = new DateRange(new Date(newTimestamps[0]), new Date(newTimestamps[1]));
          onRangeUpdate(newDates);
          if (autoAdvanceTime) {
            setAdvTimeInterval(false);
          }
        }
    };

    const lastTickPos = 1 - ((domainSpan % 86400000) / domainSpan);
    const domainSpanDays = Math.floor(domainSpan / 86400000);
    const ticks: any = [];
    for (let i = 0; i < ticksCount; i++) {
      let posValue = 0;
      if (ticksCount - 1 !== i) {
        posValue = ((i + 1) / domainSpanDays) * lastTickPos;
      } else {
        posValue = lastTickPos;
      }
      posValue *= 100;
      ticks.push(
        <div
          key={`tick-${i}`}
          className="slider-tick"
          style={{left: `${posValue}%`}}
        />,
      );
    }
    return <div className="slider-ticks" onClick={(e) => { sliderRangeUpdate(e); }}>{ticks}</div>;
}
