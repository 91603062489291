import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useVesselCursorQuery, Vessel} from "../../generated/graphql";
import VesselRegistryCompact from "./VesselRegistryCompact";
import {RFGeoLayer} from "../../@types/Layers";
import {IApplicationState} from "../../Store";
import {setTrackData, loadVesselTrack, setSelectedVesselAssociation} from "../../actions/DeckMapActions";
import {fireToast} from "../../actions/ToastActions";
import {QUERY_HOST} from "../../containers/ApolloContainer";
import VesselAssociations from "../MapMainPanel/VesselAssociations";

export interface IProps {
    feature: any;
    layer?: RFGeoLayer;
}

const MIN_TRACK_VALUE = 1;
const MAX_TRACK_VALUE = 7;

const VesselRegistryContainer: React.FunctionComponent<IProps> = (props: IProps) => {
    const [showVesselPreview, setShowVesselPreview] = React.useState<number>(1);
    const [validInput, setValidInput] = React.useState<boolean>(true);

    const dispatch = useDispatch();
    const onSelectedVesselChange = ((vesselId: string) => dispatch(setSelectedVesselAssociation(vesselId)));

    const trackLengthDays = useSelector((state: IApplicationState) => state.deck.trackLengthDays);
    const trackLoading = useSelector((state: IApplicationState) => state.deck.trackLoading);
    const deckTooltipFeatures = useSelector((state: IApplicationState) => state.deck.deckTooltipFeatures);
    const vesselAssociations = useSelector((state: IApplicationState) => state.deck.vesselAssociations);
    const selectedVessel = useSelector((state: IApplicationState) => state.deck.selectedVesselAssociation);

    const mmsi1: string | undefined = props.feature.properties.mmsi1;
    const mmsi2: string | undefined = props.feature.properties.mmsi2;
    const isSeaker = ["darkships", "rendezvous"].includes(props.feature.properties.layerName);
    const isRendezvous = props.feature.properties.mmsi1 || props.feature.properties.mmsi2;
    const mmsis = isRendezvous ? [props.feature.properties.mmsi1, props.feature.properties.mmsi2] : [props.feature.properties.mmsi];
    const vessels = useVesselCursorQuery({
        context: {clientName: QUERY_HOST.VESSEL_REGISTRY},
        variables: {mmsi: mmsis},
        onError: () => dispatch(fireToast("Error occurred searching for the vessel. Please check your network and try again.",
            "Unable to search vessel", "error")),
    });

    if (vessels.loading) {
        return (
          <div>
            {`Loading details for ${isRendezvous ? "rendezvous" : props.feature.properties.mmsi}...`}
          </div>
        );
    }

    const checkInput = (e: React.FormEvent<HTMLInputElement>) => {
        const inputValue = parseInt(e.currentTarget.value, 10);
        if (inputValue && (inputValue <= MAX_TRACK_VALUE && inputValue >= MIN_TRACK_VALUE)) {
            setValidInput(true);
        } else {
            setValidInput(false);
        }
    };

    const fireTrackErrorToast = () => {
        const toastMsg = trackLoading === 0 ? "Invalid value entered for track range" : "Previous track range data is still loading";
        dispatch(fireToast(toastMsg, "Unable to query track data", "error"));
    };

    const handleTrackLengthChange = (newLength: string) => {
        if (validInput && trackLoading === 0) {
            dispatch(setTrackData([])); // clearing previous track data
            const numOfDays = parseInt(newLength, 10);
            const mmsi = deckTooltipFeatures[0].object.properties.mmsi;
            const startDate = new Date(deckTooltipFeatures[0].object.properties.received_at_epoch * 1000);
            startDate.setUTCDate(startDate.getUTCDate() - numOfDays);
            const endDate = new Date(deckTooltipFeatures[0].object.properties.received_at_epoch * 1000);

            dispatch(loadVesselTrack(mmsi, startDate, endDate));
        } else {
            fireTrackErrorToast();
        }
    };

    const handleDarkShipTrackLength = (newLength: string) => {
        if (validInput && trackLoading === 0) {
            dispatch(setTrackData([])); // clearing previous track data
            const numOfDays = parseInt(newLength, 10);
            const mmsi = deckTooltipFeatures[0].object.properties.mmsi;
            const startDate = new Date(deckTooltipFeatures[0].object.properties.start_time);
            startDate.setUTCDate(startDate.getUTCDate() - numOfDays);
            const endDate = new Date(deckTooltipFeatures[0].object.properties.start_time);

            dispatch(loadVesselTrack(mmsi, startDate, endDate));
        } else {
            fireTrackErrorToast();
        }
    };

    const handleRendezvousTrackLength = (newLength: string) => {
        if (validInput && trackLoading === 0) {
            dispatch(setTrackData([])); // clearing previous track data
            const numOfDays = parseInt(newLength, 10);
            const v1mmsi = deckTooltipFeatures[0].object.properties.mmsi1;
            const v2mmsi = deckTooltipFeatures[0].object.properties.mmsi2;
            const startDate = new Date(deckTooltipFeatures[0].object.properties.start_time);
            startDate.setUTCDate(startDate.getUTCDate() - numOfDays);
            const endDate = new Date(deckTooltipFeatures[0].object.properties.start_time);

            dispatch(loadVesselTrack(v1mmsi, startDate, endDate));
            dispatch(loadVesselTrack(v2mmsi, startDate, endDate));
        } else {
            fireTrackErrorToast();
        }
    };

    const handleTrackLookUp = (e: React.FormEvent<HTMLInputElement>) => {
        e.stopPropagation();
        const newLength = e.currentTarget.value;

        if (deckTooltipFeatures.length) {
            const layerName = deckTooltipFeatures[0].object.properties.layerName;
            switch (layerName) {
                case "rendezvous":
                    handleRendezvousTrackLength(newLength);
                    break;
                case "darkships":
                    handleDarkShipTrackLength(newLength);
                    break;
                default:
                    handleTrackLengthChange(newLength);
            }
        }
    };

    const getVesselTrack = () => (
      <div className="vessel-track-container">
        <h2 className="vessel-track-header">Track range</h2>
        <div className="vessel-track-details">
          <input
            className={`hss-ms-input ${!validInput && "invalid-input"}`}
            defaultValue={trackLengthDays}
            type="number"
            min={MIN_TRACK_VALUE}
            max={MAX_TRACK_VALUE}
            onChange={(e) => e.stopPropagation()}
            onKeyDown={handleTrackLookUp}
            onKeyUp={(e) => e.stopPropagation()}
            onKeyPress={(e) => e.stopPropagation()}
            onInput={(e) => checkInput(e)}
            onBlur={handleTrackLookUp}
          />
          <p className="vessel-track-text">day(s) from detection.</p>
        </div>
      </div>
    );

    const getTabContent = (tabIndex: number) => {
        switch (tabIndex) {
            case 0:
                return getVesselTrack();
            case 1:
            case 2:
                return (
                  <VesselRegistryCompact
                    vesselInfo={vesselContents[showVesselPreview - 1]}
                  />
                );
            case 3:
                return (
                  <VesselAssociations
                    selectedVesselChange={onSelectedVesselChange}
                    feature={props.feature}
                    selectedVessel={selectedVessel}
                    vesselAssociations={vesselAssociations}
                    style={{width: "100%"}}
                  />
                );
            default:
                return getVesselTrack();
        }
    };

    const vesselPanelTabsElements: any = [];
    // eslint-disable-next-line no-undef
    const vesselPanelTabs: JSX.Element[] = [];
    const vesselContents: Vessel[] = [];
    if (vessels && vessels.data && vessels.data.vesselCursor && vessels.data.vesselCursor.content) {
      vessels.data.vesselCursor.content.forEach((vessel, i) => {
        let vesselNth = 1;
        if (isRendezvous && vessel!.mmsi === props.feature.properties.mmsi2) vesselNth = 2;
        if (vesselNth === 1) {
          vesselContents.unshift(vessel!);
        } else {
          vesselContents.push(vessel!);
        }
      });
    }

    if (vesselContents.length) {
      vesselContents.forEach((vessel, i) => {
        let vesselNumber: number | undefined;
        if (vessel.mmsi) {
          if (mmsi1 && vessel.mmsi === mmsi1) {
            vesselNumber = 1;
          } else if (mmsi2 && vessel.mmsi === mmsi2) {
            vesselNumber = 2;
          }
        }
        let tabText = "Vessel preview";
        if (isRendezvous && vesselNumber) {
          tabText = `Vessel ${vesselNumber} preview`;
        }
        vesselPanelTabs.push(
          <div
            key={`vessel-preview-tab-${i + 1}`}
            className={`vessel-panel-tab ${showVesselPreview === i + 1 ? "active" : ""}`}
            onClick={() => setShowVesselPreview(i + 1)}
          >
            {tabText}
          </div>);
      });
    } else if (vesselContents.length === 0 && !isSeaker) {
        vesselPanelTabs.push(
          <div
            key="vessel-preview-tab"
            className={`vessel-panel-tab ${showVesselPreview === 1 ? "active" : ""}`}
            onClick={() => setShowVesselPreview(1)}
          >
            Vessel preview
          </div>,
        );
    }

    if (!isSeaker) {
        vesselPanelTabs.push(
          <div
            key="vessel-associations-tab"
            className={`vessel-panel-tab  ${showVesselPreview === 3 ? "active" : ""}`}
            onClick={() => setShowVesselPreview(3)}
          >
            Vessel Associations
          </div>,
        );
    }

    if (vesselPanelTabs.length) {
      vesselPanelTabs.push(
        <div
          key="vessel-track-tab"
          className={`vessel-panel-tab ${showVesselPreview === 0 ? "active" : ""}`}
          onClick={() => setShowVesselPreview(0)}
        >
          {isRendezvous ? "Vessel tracks" : "Vessel track"}
        </div>,
      );
      vesselPanelTabsElements.push(<div key="vessel-panel-tabs" className="vessel-panel-tabs">{vesselPanelTabs}</div>);
      vesselPanelTabsElements.push(
        <div key="vessel-preview-content" className="vessel-panel-tab-content">
          {getTabContent(showVesselPreview)}
        </div>,
      );
    }

    if (vesselPanelTabsElements.length) {
      return (
        <div className="vessel-panel-container" data-testid="vessel-panel-container">
          {vesselPanelTabsElements}
        </div>
      );
    }

    return (
      <div className="vessel-panel-container">
        <div className="vessel-panel-tabs">
          <div className="vessel-panel-tab active">
            Vessel track
          </div>
        </div>
        <div className="vessel-panel-tab-content">
          {getVesselTrack()}
        </div>
      </div>
    );
};

export default VesselRegistryContainer;
