import * as React from "react";
import axios from "axios";
import Config from "../../Config";

interface IProps {
    apiKey: string;
    fireToast: any;
}

interface IContacts{
    customerAdmin: {name: string, email: string},
    hawkeyeRep: {name: string, email: string}
}

const StatusSupport: React.FunctionComponent<IProps> = (props: IProps) => {
    const [statusLogs, setStatusLogs] = React.useState([]);
    const [contacts, setContacts] = React.useState<IContacts | null>(null);

    React.useEffect(() => {
        const getConfig = {headers: {Authorization: `Bearer ${props.apiKey}`}};

        Promise.all([
            axios.get(`${Config.apiUrl}/about/status`, getConfig),
            axios.get(`${Config.apiUrl}/about/contacts`, getConfig),
        ])
            .then(([resp1, resp2]) => {
                setStatusLogs(resp1.data);
                setContacts(resp2.data);
            }).catch((err) => {
            console.error(err);
            props.fireToast("Unable to load status information and contact details from the database.",
                "Failed to Load Status and Contact information", "error");
        });
    }, [props]);

    const getStatusInfo = (status: string) => {
      switch (status) {
          case "OK":
              return "Normal Function. Fully Operational";
          default:
              return "Undefined Status";
        }
    };

    const createStatusLogs = () => statusLogs.map((status: any, index) => (
      <li key={index}>
        <div className="hss-ms-status-list-content">
          <div className={`hss-ms-status-bubble hss-ms-status-bubble-${status.state}`} />
          <span className="hss-ms-status-title hss-ms-bold">{status.name}</span>
          <span className="hss-ms-status-desc">{getStatusInfo(status.state)}</span>
        </div>
      </li>
    ));

    return (
      <div className="hss-ms-flex-col">
        <div className="hss-ms-status-container">
          <div className="hss-ms-h2">Status</div>
          <div className="hss-ms-hr-div" />
          <ul className="hss-ms-status hss-scrollbar">
            {statusLogs.length ? createStatusLogs() : <li className="hss-loader-container"><div className="hss-loader"><div /></div></li>}
          </ul>
        </div>

        <div className="hss-ms-support-container">
          <div className="hss-ms-h2">Support</div>
          <div className="hss-ms-hr-div" />

          {contacts ?
                    contacts.customerAdmin ? (
                      <>
                        <div className="hss-ms-support-block">
                          <div className="hss-ms-support-title hss-ms-bold">Your administrator</div>
                          <div className="hss-ms-support-name">{contacts.customerAdmin.name}</div>
                          <div className="hss-ms-support-contact">{contacts.customerAdmin.email}</div>
                        </div>

                        <div className="hss-ms-support-block">
                          <div className="hss-ms-support-title hss-ms-bold">Your HawkEye 360 representative</div>
                          <div className="hss-ms-support-name">{contacts.hawkeyeRep.name}</div>
                          <div className="hss-ms-support-contact">{contacts.hawkeyeRep.email}</div>
                        </div>
                      </>
                    ) : (
                      <div>
                        For support contact:
                        <br />
                        <a href="mailto:support@he360.com">support@he360.com</a>
                      </div>) :
                      <div className="hss-loader"><div /></div>
                    }
        </div>

      </div>
    );
};

export default StatusSupport;
