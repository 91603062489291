import React from "react";
import {IViewport} from "../@types/Viewport";
import "../styles/NavigateTo.scss";
import navigateGrayIcon from "../styles/icons/navigate_gray.svg";
import navigateWhiteIcon from "../styles/icons/navigate_white.svg";

interface Props {
    // changeViewportLatLong: any;
    viewportChanged: Function;
    viewport: IViewport;
    fireToast: Function;
}

const NavigateTo: React.FunctionComponent<Props> = (props: Props) => {
  const [searchText, setSearchText] = React.useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.currentTarget.value);
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    let errorFlag = true;
    if (searchText) {
      const trimmedSplitSearchText = searchText.trim().replace(/[^0-9.,-]/g, "").split(",");
      if (trimmedSplitSearchText.length === 2) {
        const latLong = trimmedSplitSearchText.map(Number);
        if ((Number.isFinite(latLong[0]) && Math.abs(latLong[0]) <= 90) &&
           (Number.isFinite(latLong[1]) && Math.abs(latLong[1]) <= 180)) {
          props.viewportChanged({...props.viewport, latitude: latLong[0], longitude: latLong[1]});
          errorFlag = false;
        }
      }
    }
    if (errorFlag) {
      props.fireToast("Acceptable input: lat.d, long.d decimal coordinates. Example: 38.89037, -77.03196", "Invalid input", "error");
    }
    setSearchText("");
  };

    return (
      <div className="navigate-to-container">
        <form onSubmit={handleOnSubmit}>
          <input
            className="hss-ms-input"
            placeholder="Nav to lat, long D.d"
            onChange={(e) => handleInputChange(e)}
            onKeyDown={(e) => e.stopPropagation()}
            onKeyUp={(e) => e.stopPropagation()}
            onKeyPress={(e) => e.stopPropagation()}
            value={searchText}
            type="text"
          />
          <button type="submit" onClick={handleOnSubmit}>
            <img alt="Zoom to coordinates" src={searchText ? navigateWhiteIcon : navigateGrayIcon} />
          </button>
        </form>
      </div>
    );
};

export default NavigateTo;
