import {ResetDateRange} from "../@types/DateRange";

export function coverageSquareTimeFactor(properties: any, resetDateRange: ResetDateRange) {
    const resetStartTimeStamp = resetDateRange.start / 1000;
    const resetEndTimeStamp = resetDateRange.end / 1000;

    if (resetStartTimeStamp <= properties.received_at_epoch &&
        properties.received_at_epoch <= resetEndTimeStamp) {
        return (resetEndTimeStamp - resetStartTimeStamp) / 86400;
    }
    return 0;
}
