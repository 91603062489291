export const ID_FLAG_DICTIONARY = {
  201: {
    twoLetterCode: "AL",
    threeLetterCode: "ALB",
    name: "Albania",
    flagIconIndex: {
      x: -112,
      y: -16,
      width: 16,
      height: 16,
      mask: false,
      label: "Albania",
    },
  },
  202: {
    twoLetterCode: "AD",
    threeLetterCode: "AND",
    name: "Andorra",
    flagIconIndex: {
      x: -32,
      y: -16,
      width: 16,
      height: 16,
      mask: false,
      label: "Andorra",
    },
  },
  203: {
    twoLetterCode: "AT",
    threeLetterCode: "AUT",
    name: "Austria",
    flagIconIndex: {
      x: -224,
      y: -16,
      width: 16,
      height: 16,
      mask: false,
      label: "Austria",
    },
  },
  204: {
    twoLetterCode: "PT",
    threeLetterCode: "PRT",
    name: "Azores",
    flagIconIndex: {
      x: -208,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Azores",
    },
  },
  205: {
    twoLetterCode: "BE",
    threeLetterCode: "BEL",
    name: "Belgium",
    flagIconIndex: {
      x: -80,
      y: -32,
      width: 16,
      height: 16,
      mask: false,
      label: "Belgium",
    },
  },
  206: {
    twoLetterCode: "BY",
    threeLetterCode: "BLR",
    name: "Belarus",
    flagIconIndex: {
      x: -32,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "Belarus",
    },
  },
  207: {
    twoLetterCode: "BG",
    threeLetterCode: "BGR",
    name: "Bulgaria",
    flagIconIndex: {
      x: -112,
      y: -32,
      width: 16,
      height: 16,
      mask: false,
      label: "Bulgaria",
    },
  },
  208: {
    twoLetterCode: "VA",
    threeLetterCode: "VAT",
    name: "Vatican City State",
    flagIconIndex: {
      x: -160,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Vatican City State",
    },
  },
  209: {
    twoLetterCode: "CY",
    threeLetterCode: "CYP",
    name: "Cyprus",
    flagIconIndex: {
      x: -64,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Cyprus",
    },
  },
  210: {
    twoLetterCode: "CY",
    threeLetterCode: "CYP",
    name: "Cyprus",
    flagIconIndex: {
      x: -64,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Cyprus",
    },
  },
  211: {
    twoLetterCode: "DE",
    threeLetterCode: "DEU",
    name: "Germany",
    flagIconIndex: {
      x: -96,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Germany",
    },
  },
  212: {
    twoLetterCode: "CY",
    threeLetterCode: "CYP",
    name: "Cyprus",
    flagIconIndex: {
      x: -64,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Cyprus",
    },
  },
  213: {
    twoLetterCode: "GE",
    threeLetterCode: "GEO",
    name: "Georgia",
    flagIconIndex: {
      x: -192,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "Georgia",
    },
  },
  214: {
    twoLetterCode: "MD",
    threeLetterCode: "MDA",
    name: "Moldova",
    flagIconIndex: {
      x: -16,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Moldova",
    },
  },
  215: {
    twoLetterCode: "MT",
    threeLetterCode: "MLT",
    name: "Malta",
    flagIconIndex: {
      x: -240,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Malta",
    },
  },
  216: {
    twoLetterCode: "AM",
    threeLetterCode: "ARM",
    name: "Armenia",
    flagIconIndex: {
      x: -128,
      y: -16,
      width: 16,
      height: 16,
      mask: false,
      label: "Armenia",
    },
  },
  218: {
    twoLetterCode: "DE",
    threeLetterCode: "DEU",
    name: "Germany",
    flagIconIndex: {
      x: -96,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Germany",
    },
  },
  219: {
    twoLetterCode: "DK",
    threeLetterCode: "DNK",
    name: "Denmark",
    flagIconIndex: {
      x: -128,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Denmark",
    },
  },
  220: {
    twoLetterCode: "DK",
    threeLetterCode: "DNK",
    name: "Denmark",
    flagIconIndex: {
      x: -128,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Denmark",
    },
  },
  224: {
    twoLetterCode: "ES",
    threeLetterCode: "ESP",
    name: "Spain",
    flagIconIndex: {x: 0, y: -80, width: 16, height: 16, mask: false, label: "Spain"},
  },
  225: {
    twoLetterCode: "ES",
    threeLetterCode: "ESP",
    name: "Spain",
    flagIconIndex: {x: 0, y: -80, width: 16, height: 16, mask: false, label: "Spain"},
  },
  226: {
    twoLetterCode: "FR",
    threeLetterCode: "FRA",
    name: "France",
    flagIconIndex: {
      x: -128,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "France",
    },
  },
  227: {
    twoLetterCode: "FR",
    threeLetterCode: "FRA",
    name: "France",
    flagIconIndex: {
      x: -128,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "France",
    },
  },
  228: {
    twoLetterCode: "FR",
    threeLetterCode: "FRA",
    name: "France",
    flagIconIndex: {
      x: -128,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "France",
    },
  },
  229: {
    twoLetterCode: "MT",
    threeLetterCode: "MLT",
    name: "Malta",
    flagIconIndex: {
      x: -240,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Malta",
    },
  },
  230: {
    twoLetterCode: "FI",
    threeLetterCode: "FIN",
    name: "Finland",
    flagIconIndex: {
      x: -48,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "Finland",
    },
  },
  231: {
    twoLetterCode: "FO",
    threeLetterCode: "FRO",
    name: "Faroe Islands",
    flagIconIndex: {
      x: -112,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "Faroe Islands",
    },
  },
  232: {
    twoLetterCode: "GB",
    threeLetterCode: "GBR",
    name: "United Kingdom",
    flagIconIndex: {
      x: -160,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "United Kingdom",
    },
  },
  233: {
    twoLetterCode: "GB",
    threeLetterCode: "GBR",
    name: "United Kingdom",
    flagIconIndex: {
      x: -160,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "United Kingdom",
    },
  },
  234: {
    twoLetterCode: "GB",
    threeLetterCode: "GBR",
    name: "United Kingdom",
    flagIconIndex: {
      x: -160,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "United Kingdom",
    },
  },
  235: {
    twoLetterCode: "GB",
    threeLetterCode: "GBR",
    name: "United Kingdom",
    flagIconIndex: {
      x: -160,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "United Kingdom",
    },
  },
  236: {
    twoLetterCode: "GI",
    threeLetterCode: "GIB",
    name: "Gibraltar",
    flagIconIndex: {
      x: -240,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "Gibraltar",
    },
  },
  237: {
    twoLetterCode: "GR",
    threeLetterCode: "GRC",
    name: "Greece",
    flagIconIndex: {
      x: -48,
      y: -96,
      width: 16,
      height: 16,
      mask: false,
      label: "Greece",
    },
  },
  238: {
    twoLetterCode: "HR",
    threeLetterCode: "HRV",
    name: "Croatia",
    flagIconIndex: {
      x: -176,
      y: -96,
      width: 16,
      height: 16,
      mask: false,
      label: "Croatia",
    },
  },
  239: {
    twoLetterCode: "GR",
    threeLetterCode: "GRC",
    name: "Greece",
    flagIconIndex: {
      x: -48,
      y: -96,
      width: 16,
      height: 16,
      mask: false,
      label: "Greece",
    },
  },
  240: {
    twoLetterCode: "GR",
    threeLetterCode: "GRC",
    name: "Greece",
    flagIconIndex: {
      x: -48,
      y: -96,
      width: 16,
      height: 16,
      mask: false,
      label: "Greece",
    },
  },
  241: {
    twoLetterCode: "GR",
    threeLetterCode: "GRC",
    name: "Greece",
    flagIconIndex: {
      x: -48,
      y: -96,
      width: 16,
      height: 16,
      mask: false,
      label: "Greece",
    },
  },
  242: {
    twoLetterCode: "MA",
    threeLetterCode: "MAR",
    name: "Morocco",
    flagIconIndex: {
      x: -256,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Morocco",
    },
  },
  243: {
    twoLetterCode: "HU",
    threeLetterCode: "HUN",
    name: "Hungary",
    flagIconIndex: {
      x: -208,
      y: -96,
      width: 16,
      height: 16,
      mask: false,
      label: "Hungary",
    },
  },
  244: {
    twoLetterCode: "NL",
    threeLetterCode: "NLD",
    name: "Netherlands",
    flagIconIndex: {
      x: -176,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "Netherlands",
    },
  },
  245: {
    twoLetterCode: "NL",
    threeLetterCode: "NLD",
    name: "Netherlands",
    flagIconIndex: {
      x: -176,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "Netherlands",
    },
  },
  246: {
    twoLetterCode: "NL",
    threeLetterCode: "NLD",
    name: "Netherlands",
    flagIconIndex: {
      x: -176,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "Netherlands",
    },
  },
  247: {
    twoLetterCode: "IT",
    threeLetterCode: "ITA",
    name: "Italy",
    flagIconIndex: {
      x: -96,
      y: -112,
      width: 16,
      height: 16,
      mask: false,
      label: "Italy",
    },
  },
  248: {
    twoLetterCode: "MT",
    threeLetterCode: "MLT",
    name: "Malta",
    flagIconIndex: {
      x: -240,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Malta",
    },
  },
  249: {
    twoLetterCode: "MT",
    threeLetterCode: "MLT",
    name: "Malta",
    flagIconIndex: {
      x: -240,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Malta",
    },
  },
  250: {
    twoLetterCode: "IE",
    threeLetterCode: "IRL",
    name: "Ireland",
    flagIconIndex: {
      x: -256,
      y: -96,
      width: 16,
      height: 16,
      mask: false,
      label: "Ireland",
    },
  },
  251: {
    twoLetterCode: "IS",
    threeLetterCode: "ISL",
    name: "Iceland",
    flagIconIndex: {
      x: -80,
      y: -112,
      width: 16,
      height: 16,
      mask: false,
      label: "Iceland",
    },
  },
  252: {
    twoLetterCode: "LI",
    threeLetterCode: "LIE",
    name: "Liechtenstein",
    flagIconIndex: {
      x: -128,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Liechtenstein",
    },
  },
  253: {
    twoLetterCode: "LU",
    threeLetterCode: "LUX",
    name: "Luxembourg",
    flagIconIndex: {
      x: -208,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Luxembourg",
    },
  },
  254: {
    twoLetterCode: "MC",
    threeLetterCode: "MCO",
    name: "Monaco",
    flagIconIndex: {
      x: 0,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Monaco",
    },
  },
  255: {
    twoLetterCode: "PT",
    threeLetterCode: "PRT",
    name: "Madeira",
    flagIconIndex: {
      x: -208,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Madeira",
    },
  },
  256: {
    twoLetterCode: "MT",
    threeLetterCode: "MLT",
    name: "Malta",
    flagIconIndex: {
      x: -240,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Malta",
    },
  },
  257: {
    twoLetterCode: "NO",
    threeLetterCode: "NOR",
    name: "Norway",
    flagIconIndex: {
      x: -192,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "Norway",
    },
  },
  258: {
    twoLetterCode: "NO",
    threeLetterCode: "NOR",
    name: "Norway",
    flagIconIndex: {
      x: -192,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "Norway",
    },
  },
  259: {
    twoLetterCode: "NO",
    threeLetterCode: "NOR",
    name: "Norway",
    flagIconIndex: {
      x: -192,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "Norway",
    },
  },
  261: {
    twoLetterCode: "PL",
    threeLetterCode: "POL",
    name: "Poland",
    flagIconIndex: {
      x: -144,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Poland",
    },
  },
  262: {
    twoLetterCode: "ME",
    threeLetterCode: "MNE",
    name: "Montenegro",
    flagIconIndex: {
      x: -32,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Montenegro",
    },
  },
  263: {
    twoLetterCode: "PT",
    threeLetterCode: "PRT",
    name: "Portugal",
    flagIconIndex: {
      x: -208,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Portugal",
    },
  },
  264: {
    twoLetterCode: "RO",
    threeLetterCode: "ROU",
    name: "Romania",
    flagIconIndex: {
      x: 0,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Romania",
    },
  },
  265: {
    twoLetterCode: "SE",
    threeLetterCode: "SWE",
    name: "Sweden",
    flagIconIndex: {
      x: -128,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Sweden",
    },
  },
  266: {
    twoLetterCode: "SE",
    threeLetterCode: "SWE",
    name: "Sweden",
    flagIconIndex: {
      x: -128,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Sweden",
    },
  },
  267: {
    twoLetterCode: "SK",
    threeLetterCode: "SVK",
    name: "Slovak Republic",
    flagIconIndex: {
      x: -192,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Slovak Republic",
    },
  },
  268: {
    twoLetterCode: "SM",
    threeLetterCode: "SMR",
    name: "San Marino",
    flagIconIndex: {
      x: -224,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "San Marino",
    },
  },
  269: {
    twoLetterCode: "CH",
    threeLetterCode: "CHE",
    name: "Switzerland",
    flagIconIndex: {
      x: -144,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "Switzerland",
    },
  },
  270: {
    twoLetterCode: "CZ",
    threeLetterCode: "CZE",
    name: "Czech Republic",
    flagIconIndex: {
      x: -80,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Czech Republic",
    },
  },
  271: {
    twoLetterCode: "TR",
    threeLetterCode: "TUR",
    name: "Turkey",
    flagIconIndex: {
      x: 0,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Turkey",
    },
  },
  272: {
    twoLetterCode: "UA",
    threeLetterCode: "UKR",
    name: "Ukraine",
    flagIconIndex: {
      x: -80,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Ukraine",
    },
  },
  273: {
    twoLetterCode: "RU",
    threeLetterCode: "RUS",
    name: "Russia",
    flagIconIndex: {
      x: -32,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Russia",
    },
  },
  274: {
    twoLetterCode: "MK",
    threeLetterCode: "MKD",
    name: "Macedonia",
    flagIconIndex: {
      x: -96,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Macedonia",
    },
  },
  275: {
    twoLetterCode: "LV",
    threeLetterCode: "LVA",
    name: "Latvia",
    flagIconIndex: {
      x: -224,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Latvia",
    },
  },
  276: {
    twoLetterCode: "EE",
    threeLetterCode: "EST",
    name: "Estonia",
    flagIconIndex: {
      x: -208,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Estonia",
    },
  },
  277: {
    twoLetterCode: "LT",
    threeLetterCode: "LTU",
    name: "Lithuania",
    flagIconIndex: {
      x: -192,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Lithuania",
    },
  },
  278: {
    twoLetterCode: "SI",
    threeLetterCode: "SVN",
    name: "Slovenia",
    flagIconIndex: {
      x: -176,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Slovenia",
    },
  },
  279: {
    twoLetterCode: "RS",
    threeLetterCode: "SRB",
    name: "Serbia",
    flagIconIndex: {
      x: -16,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Serbia",
    },
  },
  301: {
    twoLetterCode: "AI",
    threeLetterCode: "AIA",
    name: "Anguilla",
    flagIconIndex: {
      x: -96,
      y: -16,
      width: 16,
      height: 16,
      mask: false,
      label: "Anguilla",
    },
  },
  303: {
    twoLetterCode: "US",
    threeLetterCode: "USA",
    name: "Alaska",
    flagIconIndex: {
      x: -112,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Alaska",
    },
  },
  304: {
    twoLetterCode: "AG",
    threeLetterCode: "ATG",
    name: "Antigua and Barbuda",
    flagIconIndex: {
      x: -80,
      y: -16,
      width: 16,
      height: 16,
      mask: false,
      label: "Antigua and Barbuda",
    },
  },
  305: {
    twoLetterCode: "AG",
    threeLetterCode: "ATG",
    name: "Antigua and Barbuda",
    flagIconIndex: {
      x: -80,
      y: -16,
      width: 16,
      height: 16,
      mask: false,
      label: "Antigua and Barbuda",
    },
  },
  306: {
    twoLetterCode: "CW",
    threeLetterCode: "CUW",
    name: "Antilles",
    flagIconIndex: {
      x: -32,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Antilles",
    },
  },
  307: {
    twoLetterCode: "AW",
    threeLetterCode: "ABW",
    name: "Aruba",
    flagIconIndex: {
      x: -256,
      y: -16,
      width: 16,
      height: 16,
      mask: false,
      label: "Aruba",
    },
  },
  308: {
    twoLetterCode: "BS",
    threeLetterCode: "BHS",
    name: "Bahamas",
    flagIconIndex: {
      x: -256,
      y: -32,
      width: 16,
      height: 16,
      mask: false,
      label: "Bahamas",
    },
  },
  309: {
    twoLetterCode: "BS",
    threeLetterCode: "BHS",
    name: "Bahamas",
    flagIconIndex: {
      x: -256,
      y: -32,
      width: 16,
      height: 16,
      mask: false,
      label: "Bahamas",
    },
  },
  310: {
    twoLetterCode: "BM",
    threeLetterCode: "BMU",
    name: "Bermuda",
    flagIconIndex: {
      x: -192,
      y: -32,
      width: 16,
      height: 16,
      mask: false,
      label: "Bermuda",
    },
  },
  311: {
    twoLetterCode: "BS",
    threeLetterCode: "BMU",
    name: "Bahamas",
    flagIconIndex: {
      x: -256,
      y: -32,
      width: 16,
      height: 16,
      mask: false,
      label: "Bahamas",
    },
  },
  312: {
    twoLetterCode: "BZ",
    threeLetterCode: "BLZ",
    name: "Belize",
    flagIconIndex: {
      x: -48,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "Belize",
    },
  },
  314: {
    twoLetterCode: "BB",
    threeLetterCode: "BRB",
    name: "Barbados",
    flagIconIndex: {
      x: -48,
      y: -32,
      width: 16,
      height: 16,
      mask: false,
      label: "Barbados",
    },
  },
  316: {
    twoLetterCode: "CA",
    threeLetterCode: "CAN",
    name: "Canada",
    flagIconIndex: {
      x: -64,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "Canada",
    },
  },
  319: {
    twoLetterCode: "KY",
    threeLetterCode: "CYM",
    name: "Cayman Islands",
    flagIconIndex: {
      x: -48,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Cayman Islands",
    },
  },
  321: {
    twoLetterCode: "CR",
    threeLetterCode: "CRI",
    name: "Costa Rica",
    flagIconIndex: {
      x: -256,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "Costa Rica",
    },
  },
  323: {
    twoLetterCode: "CU",
    threeLetterCode: "CUB",
    name: "Cuba",
    flagIconIndex: {x: 0, y: -64, width: 16, height: 16, mask: false, label: "Cuba"},
  },
  325: {
    twoLetterCode: "DM",
    threeLetterCode: "DMA",
    name: "Dominica",
    flagIconIndex: {
      x: -144,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Dominica",
    },
  },
  327: {
    twoLetterCode: "DO",
    threeLetterCode: "DOM",
    name: "Dominican Republic",
    flagIconIndex: {
      x: -160,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Dominican Republic",
    },
  },
  329: {
    twoLetterCode: "GP",
    threeLetterCode: "GLP",
    name: "Guadeloupe",
    flagIconIndex: {
      x: -128,
      y: -240,
      width: 16,
      height: 16,
      mask: false,
      label: "Guadeloupe",
    },
  },
  330: {
    twoLetterCode: "GD",
    threeLetterCode: "GRD",
    name: "Grenada",
    flagIconIndex: {
      x: -176,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "Grenada",
    },
  },
  331: {
    twoLetterCode: "GL",
    threeLetterCode: "GRL",
    name: "Greenland",
    flagIconIndex: {
      x: -256,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "Greenland",
    },
  },
  332: {
    twoLetterCode: "GT",
    threeLetterCode: "GTM",
    name: "Guatemala",
    flagIconIndex: {
      x: -80,
      y: -96,
      width: 16,
      height: 16,
      mask: false,
      label: "Guatemala",
    },
  },
  335: {
    twoLetterCode: "HN",
    threeLetterCode: "HND",
    name: "Honduras",
    flagIconIndex: {
      x: -160,
      y: -96,
      width: 16,
      height: 16,
      mask: false,
      label: "Honduras",
    },
  },
  336: {
    twoLetterCode: "HT",
    threeLetterCode: "HTI",
    name: "Haiti",
    flagIconIndex: {
      x: -192,
      y: -96,
      width: 16,
      height: 16,
      mask: false,
      label: "Haiti",
    },
  },
  338: {
    twoLetterCode: "US",
    threeLetterCode: "USA",
    name: "United States of America",
    flagIconIndex: {
      x: -112,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "United States of America",
    },
  },
  339: {
    twoLetterCode: "JM",
    threeLetterCode: "JAM",
    name: "Jamaica",
    flagIconIndex: {
      x: -128,
      y: -112,
      width: 16,
      height: 16,
      mask: false,
      label: "Jamaica",
    },
  },
  341: {
    twoLetterCode: "KN",
    threeLetterCode: "KNA",
    name: "Saint Kitts and Nevis",
    flagIconIndex: {
      x: -256,
      y: -112,
      width: 16,
      height: 16,
      mask: false,
      label: "Saint Kitts and Nevis",
    },
  },
  343: {
    twoLetterCode: "LC",
    threeLetterCode: "LCA",
    name: "Saint Lucia",
    flagIconIndex: {
      x: -112,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Saint Lucia",
    },
  },
  345: {
    twoLetterCode: "MX",
    threeLetterCode: "MEX",
    name: "Mexico",
    flagIconIndex: {
      x: -32,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "Mexico",
    },
  },
  347: {
    twoLetterCode: "MQ",
    threeLetterCode: "MTQ",
    name: "Martinique",
    flagIconIndex: {
      x: -192,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Martinique",
    },
  },
  348: {
    twoLetterCode: "MS",
    threeLetterCode: "MSR",
    name: "Montserrat",
    flagIconIndex: {
      x: -224,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Montserrat",
    },
  },
  350: {
    twoLetterCode: "NI",
    threeLetterCode: "NIC",
    name: "Nicaragua",
    flagIconIndex: {
      x: -160,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "Nicaragua",
    },
  },
  351: {
    twoLetterCode: "PA",
    threeLetterCode: "PAN",
    name: "Panama",
    flagIconIndex: {
      x: -16,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Panama",
    },
  },
  352: {
    twoLetterCode: "PA",
    threeLetterCode: "PAN",
    name: "Panama",
    flagIconIndex: {
      x: -16,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Panama",
    },
  },
  353: {
    twoLetterCode: "PA",
    threeLetterCode: "PAN",
    name: "Panama",
    flagIconIndex: {
      x: -16,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Panama",
    },
  },
  354: {
    twoLetterCode: "PA",
    threeLetterCode: "PAN",
    name: "Panama",
    flagIconIndex: {
      x: -16,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Panama",
    },
  },
  355: {
    twoLetterCode: "PA",
    threeLetterCode: "PAN",
    name: "Panama",
    flagIconIndex: {
      x: -16,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Panama",
    },
  },
  356: {
    twoLetterCode: "PA",
    threeLetterCode: "PAN",
    name: "Panama",
    flagIconIndex: {
      x: -16,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Panama",
    },
  },
  357: {
    twoLetterCode: "PA",
    threeLetterCode: "PAN",
    name: "Panama",
    flagIconIndex: {
      x: -16,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Panama",
    },
  },
  358: {
    twoLetterCode: "PR",
    threeLetterCode: "PRI",
    name: "Puerto Rico",
    flagIconIndex: {
      x: -176,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Puerto Rico",
    },
  },
  359: {
    twoLetterCode: "SV",
    threeLetterCode: "SLV",
    name: "El Salvador",
    flagIconIndex: {
      x: -48,
      y: -208,
      width: 16,
      height: 16,
      mask: false,
      label: "El Salvador",
    },
  },
  361: {
    twoLetterCode: "PM",
    threeLetterCode: "SPM",
    name: "Saint Pierre and Miquelon",
    flagIconIndex: {
      x: -144,
      y: -240,
      width: 16,
      height: 16,
      mask: false,
      label: "Saint Pierre and Miquelon",
    },
  },
  362: {
    twoLetterCode: "TT",
    threeLetterCode: "TTO",
    name: "Trinidad and Tobago",
    flagIconIndex: {
      x: -16,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Trinidad and Tobago",
    },
  },
  364: {
    twoLetterCode: "TC",
    threeLetterCode: "TCA",
    name: "Turks and Caicos Islands",
    flagIconIndex: {
      x: -96,
      y: -208,
      width: 16,
      height: 16,
      mask: false,
      label: "Turks and Caicos Islands",
    },
  },
  366: {
    twoLetterCode: "US",
    threeLetterCode: "USA",
    name: "United States of America",
    flagIconIndex: {
      x: -112,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "United States of America",
    },
  },
  367: {
    twoLetterCode: "US",
    threeLetterCode: "USA",
    name: "United States of America",
    flagIconIndex: {
      x: -112,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "United States of America",
    },
  },
  368: {
    twoLetterCode: "US",
    threeLetterCode: "USA",
    name: "United States of America",
    flagIconIndex: {
      x: -112,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "United States of America",
    },
  },
  369: {
    twoLetterCode: "US",
    threeLetterCode: "USA",
    name: "United States of America",
    flagIconIndex: {
      x: -112,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "United States of America",
    },
  },
  370: {
    twoLetterCode: "PA",
    threeLetterCode: "PAN",
    name: "Panama",
    flagIconIndex: {
      x: -16,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Panama",
    },
  },
  371: {
    twoLetterCode: "PA",
    threeLetterCode: "PAN",
    name: "Panama",
    flagIconIndex: {
      x: -16,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Panama",
    },
  },
  372: {
    twoLetterCode: "PA",
    threeLetterCode: "PAN",
    name: "Panama",
    flagIconIndex: {
      x: -16,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Panama",
    },
  },
  373: {
    twoLetterCode: "PA",
    threeLetterCode: "PAN",
    name: "Panama",
    flagIconIndex: {
      x: -16,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Panama",
    },
  },
  374: {
    twoLetterCode: "PA",
    threeLetterCode: "PAN",
    name: "Panama",
    flagIconIndex: {
      x: -16,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Panama",
    },
  },
  375: {
    twoLetterCode: "VC",
    threeLetterCode: "VCT",
    name: "Saint Vincent and the Grenadines",
    flagIconIndex: {
      x: -176,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Saint Vincent and the Grenadines",
    },
  },
  376: {
    twoLetterCode: "VC",
    threeLetterCode: "VCT",
    name: "Saint Vincent and the Grenadines",
    flagIconIndex: {
      x: -176,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Saint Vincent and the Grenadines",
    },
  },
  377: {
    twoLetterCode: "VC",
    threeLetterCode: "VCT",
    name: "Saint Vincent and the Grenadines",
    flagIconIndex: {
      x: -176,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Saint Vincent and the Grenadines",
    },
  },
  378: {
    twoLetterCode: "VG",
    threeLetterCode: "VGB",
    name: "British Virgin Islands",
    flagIconIndex: {
      x: -208,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "British Virgin Islands",
    },
  },
  379: {
    twoLetterCode: "VI",
    threeLetterCode: "VIR",
    name: "United States Virgin Islands",
    flagIconIndex: {
      x: -224,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "United States Virgin Islands",
    },
  },
  401: {
    twoLetterCode: "AF",
    threeLetterCode: "AFG",
    name: "Afghanistan",
    flagIconIndex: {
      x: -64,
      y: -16,
      width: 16,
      height: 16,
      mask: false,
      label: "Afghanistan",
    },
  },
  403: {
    twoLetterCode: "SA",
    threeLetterCode: "SAU",
    name: "Saudi Arabia",
    flagIconIndex: {
      x: -64,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Saudi Arabia",
    },
  },
  405: {
    twoLetterCode: "BD",
    threeLetterCode: "BGD",
    name: "Bangladesh",
    flagIconIndex: {
      x: -64,
      y: -32,
      width: 16,
      height: 16,
      mask: false,
      label: "Bangladesh",
    },
  },
  408: {
    twoLetterCode: "BH",
    threeLetterCode: "BHR",
    name: "Bahrain",
    flagIconIndex: {
      x: -128,
      y: -32,
      width: 16,
      height: 16,
      mask: false,
      label: "Bahrain",
    },
  },
  410: {
    twoLetterCode: "BT",
    threeLetterCode: "BTN",
    name: "Bhutan",
    flagIconIndex: {
      x: 0,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "Bhutan",
    },
  },
  412: {
    twoLetterCode: "CN",
    threeLetterCode: "CHN",
    name: "China",
    flagIconIndex: {
      x: -224,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "China",
    },
  },
  413: {
    twoLetterCode: "CN",
    threeLetterCode: "CHN",
    name: "China",
    flagIconIndex: {
      x: -224,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "China",
    },
  },
  414: {
    twoLetterCode: "CN",
    threeLetterCode: "CHN",
    name: "China",
    flagIconIndex: {
      x: -224,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "China",
    },
  },
  416: {
    twoLetterCode: "TW",
    threeLetterCode: "TWN",
    name: "Taiwan",
    flagIconIndex: {
      x: -48,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Taiwan",
    },
  },
  417: {
    twoLetterCode: "LK",
    threeLetterCode: "LKA",
    name: "Sri Lanka",
    flagIconIndex: {
      x: -144,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Sri Lanka",
    },
  },
  419: {
    twoLetterCode: "IN",
    threeLetterCode: "IND",
    name: "India",
    flagIconIndex: {
      x: -32,
      y: -112,
      width: 16,
      height: 16,
      mask: false,
      label: "India",
    },
  },
  422: {
    twoLetterCode: "IR",
    threeLetterCode: "IRN",
    name: "Iran",
    flagIconIndex: {
      x: -64,
      y: -112,
      width: 16,
      height: 16,
      mask: false,
      label: "Iran",
    },
  },
  423: {
    twoLetterCode: "AZ",
    threeLetterCode: "AZE",
    name: "Azerbaijan",
    flagIconIndex: {
      x: -16,
      y: -32,
      width: 16,
      height: 16,
      mask: false,
      label: "Azerbaijan",
    },
  },
  425: {
    twoLetterCode: "IQ",
    threeLetterCode: "IRQ",
    name: "Iraq",
    flagIconIndex: {
      x: -48,
      y: -112,
      width: 16,
      height: 16,
      mask: false,
      label: "Iraq",
    },
  },
  428: {
    twoLetterCode: "IL",
    threeLetterCode: "ISR",
    name: "Israel",
    flagIconIndex: {
      x: 0,
      y: -112,
      width: 16,
      height: 16,
      mask: false,
      label: "Israel",
    },
  },
  431: {
    twoLetterCode: "JP",
    threeLetterCode: "JPN",
    name: "Japan",
    flagIconIndex: {
      x: -160,
      y: -112,
      width: 16,
      height: 16,
      mask: false,
      label: "Japan",
    },
  },
  432: {
    twoLetterCode: "JP",
    threeLetterCode: "JPN",
    name: "Japan",
    flagIconIndex: {
      x: -160,
      y: -112,
      width: 16,
      height: 16,
      mask: false,
      label: "Japan",
    },
  },
  434: {
    twoLetterCode: "TM",
    threeLetterCode: "TKM",
    name: "Turkmenistan",
    flagIconIndex: {
      x: -224,
      y: -208,
      width: 16,
      height: 16,
      mask: false,
      label: "Turkmenistan",
    },
  },
  436: {
    twoLetterCode: "KZ",
    threeLetterCode: "KAZ",
    name: "Kazakhstan",
    flagIconIndex: {
      x: -64,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Kazakhstan",
    },
  },
  437: {
    twoLetterCode: "UZ",
    threeLetterCode: "UZB",
    name: "Uzbekistan",
    flagIconIndex: {
      x: -144,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Uzbekistan",
    },
  },
  438: {
    twoLetterCode: "JO",
    threeLetterCode: "JOR",
    name: "Jordan",
    flagIconIndex: {
      x: -144,
      y: -112,
      width: 16,
      height: 16,
      mask: false,
      label: "Jordan",
    },
  },
  440: {
    twoLetterCode: "KR",
    threeLetterCode: "KOR",
    name: "Korea",
    flagIconIndex: {
      x: -16,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Korea",
    },
  },
  441: {
    twoLetterCode: "KR",
    threeLetterCode: "KOR",
    name: "Korea",
    flagIconIndex: {
      x: -16,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Korea",
    },
  },
  443: {
    twoLetterCode: "PS",
    threeLetterCode: "PSE",
    name: "State of Palestine",
    flagIconIndex: {
      x: -192,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "State of Palestine",
    },
  },
  445: {
    twoLetterCode: "KP",
    threeLetterCode: "PRK",
    name: "Democratic People's Republic of Korea",
    flagIconIndex: {
      x: 0,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Democratic People's Republic of Korea",
    },
  },
  447: {
    twoLetterCode: "KW",
    threeLetterCode: "KWT",
    name: "Kuwait",
    flagIconIndex: {
      x: -32,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Kuwait",
    },
  },
  450: {
    twoLetterCode: "LB",
    threeLetterCode: "LBN",
    name: "Lebanon",
    flagIconIndex: {
      x: -96,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Lebanon",
    },
  },
  451: {
    twoLetterCode: "KG",
    threeLetterCode: "KGZ",
    name: "Kyrgyz Republic",
    flagIconIndex: {
      x: -192,
      y: -112,
      width: 16,
      height: 16,
      mask: false,
      label: "Kyrgyz Republic",
    },
  },
  453: {
    twoLetterCode: "MO",
    threeLetterCode: "MAC",
    name: "Macao",
    flagIconIndex: {
      x: -160,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Macao",
    },
  },
  455: {
    twoLetterCode: "MV",
    threeLetterCode: "MDV",
    name: "Maldives",
    flagIconIndex: {
      x: 0,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "Maldives",
    },
  },
  457: {
    twoLetterCode: "MN",
    threeLetterCode: "MNG",
    name: "Mongolia",
    flagIconIndex: {
      x: -144,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Mongolia",
    },
  },
  459: {
    twoLetterCode: "NP",
    threeLetterCode: "NPL",
    name: "Nepal",
    flagIconIndex: {
      x: -208,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "Nepal",
    },
  },
  461: {
    twoLetterCode: "OM",
    threeLetterCode: "OMN",
    name: "Oman",
    flagIconIndex: {x: 0, y: -176, width: 16, height: 16, mask: false, label: "Oman"},
  },
  463: {
    twoLetterCode: "PK",
    threeLetterCode: "PAK",
    name: "Pakistan",
    flagIconIndex: {
      x: -128,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Pakistan",
    },
  },
  466: {
    twoLetterCode: "QA",
    threeLetterCode: "QAT",
    name: "Qatar (State of)",
    flagIconIndex: {
      x: -256,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Qatar (State of)",
    },
  },
  468: {
    twoLetterCode: "SY",
    threeLetterCode: "SYR",
    name: "Syrian Arab Republic",
    flagIconIndex: {
      x: -64,
      y: -208,
      width: 16,
      height: 16,
      mask: false,
      label: "Syrian Arab Republic",
    },
  },
  470: {
    twoLetterCode: "AE",
    threeLetterCode: "ARE",
    name: "United Arab Emirates",
    flagIconIndex: {
      x: -48,
      y: -16,
      width: 16,
      height: 16,
      mask: false,
      label: "United Arab Emirates",
    },
  },
  472: {
    twoLetterCode: "TJ",
    threeLetterCode: "TJK",
    name: "Tajikistan",
    flagIconIndex: {
      x: -176,
      y: -208,
      width: 16,
      height: 16,
      mask: false,
      label: "Tajikistan",
    },
  },
  473: {
    twoLetterCode: "YE",
    threeLetterCode: "YEM",
    name: "Yemen",
    flagIconIndex: {x: -32, y: -240, width: 16, height: 16, mask: false, label: "Yemen"},
  },
  475: {
    twoLetterCode: "YE",
    threeLetterCode: "YEM",
    name: "Yemen",
    flagIconIndex: {x: -32, y: -240, width: 16, height: 16, mask: false, label: "Yemen"},
  },
  477: {
    twoLetterCode: "HK",
    threeLetterCode: "HKG",
    name: "Hong Kong",
    flagIconIndex: {
      x: -144,
      y: -96,
      width: 16,
      height: 16,
      mask: false,
      label: "Hong Kong",
    },
  },
  478: {
    twoLetterCode: "BA",
    threeLetterCode: "BIH",
    name: "Bosnia and Herzegovina",
    flagIconIndex: {
      x: -32,
      y: -32,
      width: 16,
      height: 16,
      mask: false,
      label: "Bosnia and Herzegovina",
    },
  },
  501: {
    twoLetterCode: "FR",
    threeLetterCode: "FRA",
    name: "Adelie Land",
    flagIconIndex: {
      x: -128,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "Adelie Land",
    },
  },
  503: {
    twoLetterCode: "AU",
    threeLetterCode: "AUS",
    name: "Australia",
    flagIconIndex: {
      x: -240,
      y: -16,
      width: 16,
      height: 16,
      mask: false,
      label: "Australia",
    },
  },
  506: {
    twoLetterCode: "MM",
    threeLetterCode: "MMR",
    name: "Myanmar",
    flagIconIndex: {
      x: -128,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Myanmar",
    },
  },
  508: {
    twoLetterCode: "BN",
    threeLetterCode: "BRN",
    name: "Brunei Darussalam",
    flagIconIndex: {
      x: -208,
      y: -32,
      width: 16,
      height: 16,
      mask: false,
      label: "Brunei Darussalam",
    },
  },
  510: {
    twoLetterCode: "FM",
    threeLetterCode: "FSM",
    name: "Micronesia",
    flagIconIndex: {
      x: -96,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "Micronesia",
    },
  },
  511: {
    twoLetterCode: "PW",
    threeLetterCode: "PLW",
    name: "Palau",
    flagIconIndex: {
      x: -224,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Palau",
    },
  },
  512: {
    twoLetterCode: "NZ",
    threeLetterCode: "NZL",
    name: "New Zealand",
    flagIconIndex: {
      x: -256,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "New Zealand",
    },
  },
  514: {
    twoLetterCode: "KH",
    threeLetterCode: "KHM",
    name: "Cambodia",
    flagIconIndex: {
      x: -208,
      y: -112,
      width: 16,
      height: 16,
      mask: false,
      label: "Cambodia",
    },
  },
  515: {
    twoLetterCode: "KH",
    threeLetterCode: "KHM",
    name: "Cambodia",
    flagIconIndex: {
      x: -208,
      y: -112,
      width: 16,
      height: 16,
      mask: false,
      label: "Cambodia",
    },
  },
  516: {
    twoLetterCode: "CX",
    threeLetterCode: "CXR",
    name: "Christmas Island",
    flagIconIndex: {
      x: -48,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Christmas Island",
    },
  },
  518: {
    twoLetterCode: "CK",
    threeLetterCode: "COK",
    name: "Cook Islands",
    flagIconIndex: {
      x: -176,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "Cook Islands",
    },
  },
  520: {
    twoLetterCode: "FJ",
    threeLetterCode: "FJI",
    name: "Fiji",
    flagIconIndex: {x: -64, y: -80, width: 16, height: 16, mask: false, label: "Fiji"},
  },
  523: {
    twoLetterCode: "CC",
    threeLetterCode: "CCK",
    name: "Cocos (Keeling) Islands",
    flagIconIndex: {
      x: -80,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "Cocos (Keeling) Islands",
    },
  },
  525: {
    twoLetterCode: "ID",
    threeLetterCode: "IDN",
    name: "Indonesia",
    flagIconIndex: {
      x: -240,
      y: -96,
      width: 16,
      height: 16,
      mask: false,
      label: "Indonesia",
    },
  },
  529: {
    twoLetterCode: "KI",
    threeLetterCode: "KIR",
    name: "Kiribati",
    flagIconIndex: {
      x: -224,
      y: -112,
      width: 16,
      height: 16,
      mask: false,
      label: "Kiribati",
    },
  },
  531: {
    twoLetterCode: "LA",
    threeLetterCode: "LAO",
    name: "Lao People's Democratic Republic",
    flagIconIndex: {
      x: -80,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Lao People's Democratic Republic",
    },
  },
  533: {
    twoLetterCode: "MY",
    threeLetterCode: "MYS",
    name: "Malaysia",
    flagIconIndex: {
      x: -48,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "Malaysia",
    },
  },
  536: {
    twoLetterCode: "MP",
    threeLetterCode: "MNP",
    name: "Northern Mariana Islands",
    flagIconIndex: {
      x: -176,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Northern Mariana Islands",
    },
  },
  538: {
    twoLetterCode: "MH",
    threeLetterCode: "MHL",
    name: "Marshall Islands",
    flagIconIndex: {
      x: -80,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Marshall Islands",
    },
  },
  540: {
    twoLetterCode: "NC",
    threeLetterCode: "NCL",
    name: "New Caledonia",
    flagIconIndex: {
      x: -96,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "New Caledonia",
    },
  },
  542: {
    twoLetterCode: "NU",
    threeLetterCode: "NIU",
    name: "Niue",
    flagIconIndex: {
      x: -240,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "Niue",
    },
  },
  544: {
    twoLetterCode: "NR",
    threeLetterCode: "NRU",
    name: "Nauru",
    flagIconIndex: {
      x: -224,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "Nauru",
    },
  },
  546: {
    twoLetterCode: "PF",
    threeLetterCode: "PYF",
    name: "French Polynesia",
    flagIconIndex: {
      x: -48,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "French Polynesia",
    },
  },
  548: {
    twoLetterCode: "PH",
    threeLetterCode: "PHL",
    name: "Philippines",
    flagIconIndex: {
      x: -80,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Philippines",
    },
  },
  553: {
    twoLetterCode: "PG",
    threeLetterCode: "PNG",
    name: "Papua New Guinea",
    flagIconIndex: {
      x: -64,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Papua New Guinea",
    },
  },
  555: {
    twoLetterCode: "PN",
    threeLetterCode: "PCN",
    name: "Pitcairn Island",
    flagIconIndex: {
      x: -160,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Pitcairn Island",
    },
  },
  557: {
    twoLetterCode: "SB",
    threeLetterCode: "SLB",
    name: "Solomon Islands",
    flagIconIndex: {
      x: -80,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Solomon Islands",
    },
  },
  559: {
    twoLetterCode: "AS",
    threeLetterCode: "ASM",
    name: "American Samoa",
    flagIconIndex: {
      x: -208,
      y: -16,
      width: 16,
      height: 16,
      mask: false,
      label: "American Samoa",
    },
  },
  561: {
    twoLetterCode: "WS",
    threeLetterCode: "WSM",
    name: "Samoa",
    flagIconIndex: {x: -16, y: -240, width: 16, height: 16, mask: false, label: "Samoa"},
  },
  563: {
    twoLetterCode: "SG",
    threeLetterCode: "SGP",
    name: "Singapore",
    flagIconIndex: {
      x: -144,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Singapore",
    },
  },
  564: {
    twoLetterCode: "SG",
    threeLetterCode: "SGP",
    name: "Singapore",
    flagIconIndex: {
      x: -144,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Singapore",
    },
  },
  565: {
    twoLetterCode: "SG",
    threeLetterCode: "SGP",
    name: "Singapore",
    flagIconIndex: {
      x: -144,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Singapore",
    },
  },
  566: {
    twoLetterCode: "SG",
    threeLetterCode: "SGP",
    name: "Singapore",
    flagIconIndex: {
      x: -144,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Singapore",
    },
  },
  567: {
    twoLetterCode: "TH",
    threeLetterCode: "THA",
    name: "Thailand",
    flagIconIndex: {
      x: -160,
      y: -208,
      width: 16,
      height: 16,
      mask: false,
      label: "Thailand",
    },
  },
  570: {
    twoLetterCode: "TO",
    threeLetterCode: "TON",
    name: "Tonga",
    flagIconIndex: {
      x: -256,
      y: -208,
      width: 16,
      height: 16,
      mask: false,
      label: "Tonga",
    },
  },
  572: {
    twoLetterCode: "TV",
    threeLetterCode: "TUV",
    name: "Tuvalu",
    flagIconIndex: {
      x: -32,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Tuvalu",
    },
  },
  574: {
    twoLetterCode: "VN",
    threeLetterCode: "VNM",
    name: "Viet Nam",
    flagIconIndex: {
      x: -240,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Viet Nam",
    },
  },
  576: {
    twoLetterCode: "VU",
    threeLetterCode: "VUT",
    name: "Vanuatu",
    flagIconIndex: {
      x: -256,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Vanuatu",
    },
  },
  577: {
    twoLetterCode: "VU",
    threeLetterCode: "VUT",
    name: "Vanuatu",
    flagIconIndex: {
      x: -256,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Vanuatu",
    },
  },
  578: {
    twoLetterCode: "WF",
    threeLetterCode: "WLF",
    name: "Wallis and Futuna Islands",
    flagIconIndex: {
      x: 0,
      y: -240,
      width: 16,
      height: 16,
      mask: false,
      label: "Wallis and Futuna Islands",
    },
  },
  601: {
    twoLetterCode: "ZA",
    threeLetterCode: "ZAF",
    name: "South Africa",
    flagIconIndex: {
      x: -64,
      y: -240,
      width: 16,
      height: 16,
      mask: false,
      label: "South Africa",
    },
  },
  603: {
    twoLetterCode: "AO",
    threeLetterCode: "AGO",
    name: "Angola",
    flagIconIndex: {
      x: -160,
      y: -16,
      width: 16,
      height: 16,
      mask: false,
      label: "Angola",
    },
  },
  605: {
    twoLetterCode: "DZ",
    threeLetterCode: "DZA",
    name: "Algeria",
    flagIconIndex: {
      x: -176,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Algeria",
    },
  },
  607: {
    twoLetterCode: "FR",
    threeLetterCode: "FRA",
    name: "Saint Paul and Amsterdam Islands",
    flagIconIndex: {
      x: -128,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "Saint Paul and Amsterdam Islands",
    },
  },
  608: {
    twoLetterCode: "GB",
    threeLetterCode: "GBR",
    name: "Ascension Island",
    flagIconIndex: {
      x: -160,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "Ascension Island",
    },
  },
  609: {
    twoLetterCode: "BI",
    threeLetterCode: "BDI",
    name: "Burundi",
    flagIconIndex: {
      x: -144,
      y: -32,
      width: 16,
      height: 16,
      mask: false,
      label: "Burundi",
    },
  },
  610: {
    twoLetterCode: "BJ",
    threeLetterCode: "BEN",
    name: "Benin",
    flagIconIndex: {
      x: -160,
      y: -32,
      width: 16,
      height: 16,
      mask: false,
      label: "Benin",
    },
  },
  611: {
    twoLetterCode: "BW",
    threeLetterCode: "BWA",
    name: "Botswana",
    flagIconIndex: {
      x: -16,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "Botswana",
    },
  },
  612: {
    twoLetterCode: "CF",
    threeLetterCode: "CAF",
    name: "Central African Republic",
    flagIconIndex: {
      x: -112,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "Central African Republic",
    },
  },
  613: {
    twoLetterCode: "CM",
    threeLetterCode: "CMR",
    name: "Cameroon",
    flagIconIndex: {
      x: -208,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "Cameroon",
    },
  },
  615: {
    twoLetterCode: "CG",
    threeLetterCode: "COG",
    name: "Congo",
    flagIconIndex: {
      x: -128,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "Congo",
    },
  },
  616: {
    twoLetterCode: "KM",
    threeLetterCode: "COM",
    name: "Comoros",
    flagIconIndex: {
      x: -240,
      y: -112,
      width: 16,
      height: 16,
      mask: false,
      label: "Comoros",
    },
  },
  617: {
    twoLetterCode: "CV",
    threeLetterCode: "CPV",
    name: "Cabo Verde",
    flagIconIndex: {
      x: -16,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Cabo Verde",
    },
  },
  618: {
    twoLetterCode: "FR",
    threeLetterCode: "FRA",
    name: "Crozet Archipelago",
    flagIconIndex: {
      x: -128,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "Crozet Archipelago",
    },
  },
  619: {
    twoLetterCode: "CI",
    threeLetterCode: "CIV",
    name: "Ivory Coast",
    flagIconIndex: {
      x: -160,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "Ivory Coast",
    },
  },
  620: {
    twoLetterCode: "KM",
    threeLetterCode: "COM",
    name: "Comoros",
    flagIconIndex: {
      x: -240,
      y: -112,
      width: 16,
      height: 16,
      mask: false,
      label: "Comoros",
    },
  },
  621: {
    twoLetterCode: "DJ",
    threeLetterCode: "DJI",
    name: "Djibouti",
    flagIconIndex: {
      x: -112,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Djibouti",
    },
  },
  622: {
    twoLetterCode: "EG",
    threeLetterCode: "EGY",
    name: "Egypt",
    flagIconIndex: {
      x: -224,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Egypt",
    },
  },
  624: {
    twoLetterCode: "ET",
    threeLetterCode: "ETH",
    name: "Ethiopia",
    flagIconIndex: {
      x: -16,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "Ethiopia",
    },
  },
  625: {
    twoLetterCode: "ER",
    threeLetterCode: "ERI",
    name: "Eritrea",
    flagIconIndex: {
      x: -256,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Eritrea",
    },
  },
  626: {
    twoLetterCode: "GA",
    threeLetterCode: "GAB",
    name: "Gabonese Republic",
    flagIconIndex: {
      x: -144,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "Gabonese Republic",
    },
  },
  627: {
    twoLetterCode: "GH",
    threeLetterCode: "GHA",
    name: "Ghana",
    flagIconIndex: {
      x: -224,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "Ghana",
    },
  },
  629: {
    twoLetterCode: "GM",
    threeLetterCode: "GMB",
    name: "Gambia",
    flagIconIndex: {
      x: 0,
      y: -96,
      width: 16,
      height: 16,
      mask: false,
      label: "Gambia",
    },
  },
  630: {
    twoLetterCode: "GW",
    threeLetterCode: "GNB",
    name: "Guinea-Bissau",
    flagIconIndex: {
      x: -112,
      y: -96,
      width: 16,
      height: 16,
      mask: false,
      label: "Guinea-Bissau",
    },
  },
  631: {
    twoLetterCode: "GQ",
    threeLetterCode: "GNQ",
    name: "Equatorial Guinea",
    flagIconIndex: {
      x: -32,
      y: -96,
      width: 16,
      height: 16,
      mask: false,
      label: "Equatorial Guinea",
    },
  },
  632: {
    twoLetterCode: "GN",
    threeLetterCode: "GIN",
    name: "Guinea",
    flagIconIndex: {
      x: -16,
      y: -96,
      width: 16,
      height: 16,
      mask: false,
      label: "Guinea",
    },
  },
  633: {
    twoLetterCode: "BF",
    threeLetterCode: "BFA",
    name: "Burkina Faso",
    flagIconIndex: {
      x: -96,
      y: -32,
      width: 16,
      height: 16,
      mask: false,
      label: "Burkina Faso",
    },
  },
  634: {
    twoLetterCode: "KE",
    threeLetterCode: "KEN",
    name: "Kenya",
    flagIconIndex: {
      x: -176,
      y: -112,
      width: 16,
      height: 16,
      mask: false,
      label: "Kenya",
    },
  },
  635: {
    twoLetterCode: "FR",
    threeLetterCode: "FRA",
    name: "Kerguelen Islands",
    flagIconIndex: {
      x: -128,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "Kerguelen Islands",
    },
  },
  636: {
    twoLetterCode: "LR",
    threeLetterCode: "LBR",
    name: "Liberia",
    flagIconIndex: {
      x: -160,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Liberia",
    },
  },
  637: {
    twoLetterCode: "LR",
    threeLetterCode: "LBR",
    name: "Liberia",
    flagIconIndex: {
      x: -160,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Liberia",
    },
  },
  638: {
    twoLetterCode: "SS",
    threeLetterCode: "SSD",
    name: "South Sudan",
    flagIconIndex: {
      x: -16,
      y: -208,
      width: 16,
      height: 16,
      mask: false,
      label: "South Sudan",
    },
  },
  642: {
    twoLetterCode: "LY",
    threeLetterCode: "LBY",
    name: "Libya",
    flagIconIndex: {
      x: -240,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Libya",
    },
  },
  644: {
    twoLetterCode: "LS",
    threeLetterCode: "LSO",
    name: "Lesotho",
    flagIconIndex: {
      x: -176,
      y: -128,
      width: 16,
      height: 16,
      mask: false,
      label: "Lesotho",
    },
  },
  645: {
    twoLetterCode: "MU",
    threeLetterCode: "MUS",
    name: "Mauritius",
    flagIconIndex: {
      x: -256,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Mauritius",
    },
  },
  647: {
    twoLetterCode: "MG",
    threeLetterCode: "MDG",
    name: "Madagascar",
    flagIconIndex: {
      x: -64,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Madagascar",
    },
  },
  649: {
    twoLetterCode: "ML",
    threeLetterCode: "MLI",
    name: "Mali",
    flagIconIndex: {
      x: -112,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Mali",
    },
  },
  650: {
    twoLetterCode: "MZ",
    threeLetterCode: "MOZ",
    name: "Mozambique",
    flagIconIndex: {
      x: -64,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "Mozambique",
    },
  },
  654: {
    twoLetterCode: "MR",
    threeLetterCode: "MRT",
    name: "Mauritania",
    flagIconIndex: {
      x: -208,
      y: -144,
      width: 16,
      height: 16,
      mask: false,
      label: "Mauritania",
    },
  },
  655: {
    twoLetterCode: "MW",
    threeLetterCode: "MWI",
    name: "Malawi",
    flagIconIndex: {
      x: -16,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "Malawi",
    },
  },
  656: {
    twoLetterCode: "NE",
    threeLetterCode: "NER",
    name: "Niger",
    flagIconIndex: {
      x: -112,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "Niger",
    },
  },
  657: {
    twoLetterCode: "NG",
    threeLetterCode: "NGA",
    name: "Nigeria",
    flagIconIndex: {
      x: -144,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "Nigeria",
    },
  },
  659: {
    twoLetterCode: "NA",
    threeLetterCode: "NAM",
    name: "Namibia",
    flagIconIndex: {
      x: -80,
      y: -160,
      width: 16,
      height: 16,
      mask: false,
      label: "Namibia",
    },
  },
  660: {
    twoLetterCode: "RE",
    threeLetterCode: "REU",
    name: "Reunion",
    flagIconIndex: {x: -128, y: -80, width: 16, height: 16, mask: false, label: "Reunion"},
  },
  661: {
    twoLetterCode: "RW",
    threeLetterCode: "RWA",
    name: "Rwanda",
    flagIconIndex: {
      x: -48,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Rwanda",
    },
  },
  662: {
    twoLetterCode: "SD",
    threeLetterCode: "SDN",
    name: "Sudan",
    flagIconIndex: {
      x: -112,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Sudan",
    },
  },
  663: {
    twoLetterCode: "SN",
    threeLetterCode: "SEN",
    name: "Senegal",
    flagIconIndex: {
      x: -240,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Senegal",
    },
  },
  664: {
    twoLetterCode: "SC",
    threeLetterCode: "SYC",
    name: "Seychelles",
    flagIconIndex: {
      x: -96,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Seychelles",
    },
  },
  665: {
    twoLetterCode: "SH",
    threeLetterCode: "SHN",
    name: "Saint Helena",
    flagIconIndex: {
      x: -160,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Saint Helena",
    },
  },
  666: {
    twoLetterCode: "SO",
    threeLetterCode: "SOM",
    name: "Somali Democratic Republic",
    flagIconIndex: {
      x: -256,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Somali Democratic Republic",
    },
  },
  667: {
    twoLetterCode: "SL",
    threeLetterCode: "SLE",
    name: "Sierra Leone",
    flagIconIndex: {
      x: -208,
      y: -192,
      width: 16,
      height: 16,
      mask: false,
      label: "Sierra Leone",
    },
  },
  668: {
    twoLetterCode: "ST",
    threeLetterCode: "STP",
    name: "Sao Tome and Principe",
    flagIconIndex: {
      x: -32,
      y: -208,
      width: 16,
      height: 16,
      mask: false,
      label: "Sao Tome and Principe",
    },
  },
  669: {
    twoLetterCode: "SZ",
    threeLetterCode: "SWZ",
    name: "Swaziland",
    flagIconIndex: {
      x: -80,
      y: -208,
      width: 16,
      height: 16,
      mask: false,
      label: "Swaziland",
    },
  },
  670: {
    twoLetterCode: "TD",
    threeLetterCode: "TCD",
    name: "Chad",
    flagIconIndex: {
      x: -112,
      y: -208,
      width: 16,
      height: 16,
      mask: false,
      label: "Chad",
    },
  },
  671: {
    twoLetterCode: "TG",
    threeLetterCode: "TGO",
    name: "Togolese Republic",
    flagIconIndex: {
      x: -144,
      y: -208,
      width: 16,
      height: 16,
      mask: false,
      label: "Togolese Republic",
    },
  },
  672: {
    twoLetterCode: "TN",
    threeLetterCode: "TUN",
    name: "Tunisian Republic",
    flagIconIndex: {
      x: -240,
      y: -208,
      width: 16,
      height: 16,
      mask: false,
      label: "Tunisian Republic",
    },
  },
  674: {
    twoLetterCode: "TZ",
    threeLetterCode: "TZA",
    name: "Tanzania",
    flagIconIndex: {
      x: -64,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Tanzania",
    },
  },
  675: {
    twoLetterCode: "UG",
    threeLetterCode: "UGA",
    name: "Uganda",
    flagIconIndex: {
      x: -96,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Uganda",
    },
  },
  676: {
    twoLetterCode: "CD",
    threeLetterCode: "COD",
    name: "Democratic Republic of the Congo",
    flagIconIndex: {
      x: -96,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "Democratic Republic of the Congo",
    },
  },
  677: {
    twoLetterCode: "TZ",
    threeLetterCode: "TZA",
    name: "Tanzania",
    flagIconIndex: {
      x: -64,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Tanzania",
    },
  },
  678: {
    twoLetterCode: "ZM",
    threeLetterCode: "ZMB",
    name: "Zambia",
    flagIconIndex: {x: -80, y: -240, width: 16, height: 16, mask: false, label: "Zambia"},
  },
  679: {
    twoLetterCode: "ZW",
    threeLetterCode: "ZWE",
    name: "Zimbabwe",
    flagIconIndex: {x: -96, y: -240, width: 16, height: 16, mask: false, label: "Zimbabwe"},
  },
  701: {
    twoLetterCode: "AR",
    threeLetterCode: "ARG",
    name: "Argentine Republic",
    flagIconIndex: {
      x: -192,
      y: -16,
      width: 16,
      height: 16,
      mask: false,
      label: "Argentine Republic",
    },
  },
  710: {
    twoLetterCode: "BR",
    threeLetterCode: "BRA",
    name: "Brazil",
    flagIconIndex: {
      x: -240,
      y: -32,
      width: 16,
      height: 16,
      mask: false,
      label: "Brazil",
    },
  },
  720: {
    twoLetterCode: "BO",
    threeLetterCode: "BOL",
    name: "Bolivia",
    flagIconIndex: {
      x: -224,
      y: -32,
      width: 16,
      height: 16,
      mask: false,
      label: "Bolivia",
    },
  },
  725: {
    twoLetterCode: "CL",
    threeLetterCode: "CHL",
    name: "Chile",
    flagIconIndex: {
      x: -192,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "Chile",
    },
  },
  730: {
    twoLetterCode: "CO",
    threeLetterCode: "COL",
    name: "Colombia",
    flagIconIndex: {
      x: -240,
      y: -48,
      width: 16,
      height: 16,
      mask: false,
      label: "Colombia",
    },
  },
  735: {
    twoLetterCode: "EC",
    threeLetterCode: "ECU",
    name: "Ecuador",
    flagIconIndex: {
      x: -192,
      y: -64,
      width: 16,
      height: 16,
      mask: false,
      label: "Ecuador",
    },
  },
  740: {
    twoLetterCode: "FK",
    threeLetterCode: "FLK",
    name: "Falkland Islands",
    flagIconIndex: {
      x: -80,
      y: -80,
      width: 16,
      height: 16,
      mask: false,
      label: "Falkland Islands",
    },
  },
  745: {
    twoLetterCode: "GF",
    threeLetterCode: "GUF",
    name: "Guiana",
    flagIconIndex: {x: -112, y: -240, width: 16, height: 16, mask: false, label: "Guiana"},
  },
  750: {
    twoLetterCode: "GY",
    threeLetterCode: "GUY",
    name: "Guyana",
    flagIconIndex: {
      x: -128,
      y: -96,
      width: 16,
      height: 16,
      mask: false,
      label: "Guyana",
    },
  },
  755: {
    twoLetterCode: "PY",
    threeLetterCode: "PRY",
    name: "Paraguay",
    flagIconIndex: {
      x: -240,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Paraguay",
    },
  },
  760: {
    twoLetterCode: "PE",
    threeLetterCode: "PER",
    name: "Peru",
    flagIconIndex: {
      x: -32,
      y: -176,
      width: 16,
      height: 16,
      mask: false,
      label: "Peru",
    },
  },
  765: {
    twoLetterCode: "SR",
    threeLetterCode: "SUR",
    name: "Suriname",
    flagIconIndex: {
      x: 0,
      y: -208,
      width: 16,
      height: 16,
      mask: false,
      label: "Suriname",
    },
  },
  770: {
    twoLetterCode: "UY",
    threeLetterCode: "URY",
    name: "Uruguay",
    flagIconIndex: {
      x: -128,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Uruguay",
    },
  },
  775: {
    twoLetterCode: "VE",
    threeLetterCode: "VEN",
    name: "Venezuela",
    flagIconIndex: {
      x: -192,
      y: -224,
      width: 16,
      height: 16,
      mask: false,
      label: "Venezuela",
    },
  },
};

export interface FlagIconIndex {
  x: number;
  y: number;
  width: number;
  height: number;
  mask: boolean;
  label: string;
}

export interface FlagData {
  twoLetterCode: string;
  threeLetterCode: string;
  name: string;
  flagIconIndex: FlagIconIndex;
}

export function mmsiFlagFinder(identifier:string | undefined | null): FlagData {
  if (identifier && identifier.trim().length === 9) {
    const country = ID_FLAG_DICTIONARY[Number(identifier.substr(0, 3)) as keyof typeof ID_FLAG_DICTIONARY];
    if (country) {
      return country;
    }
  }
  return {
    twoLetterCode: "UI",
    threeLetterCode: "UID",
    name: "Unknown",
    flagIconIndex: {x: 0, y: -16, width: 16, height: 16, mask: false, label: "Unknown"},
  };
}

export function twoLetterCodeLookup(identifier:string | undefined | null): FlagData {
  if (identifier && identifier.trim().length === 2) {
    const twoLetterCode = Object.values(ID_FLAG_DICTIONARY)
      .find((countryObj) => countryObj.twoLetterCode === identifier);
    if (twoLetterCode) {
      return twoLetterCode;
    }
  }
  return {
    twoLetterCode: "UI",
    threeLetterCode: "UID",
    name: "Unknown",
    flagIconIndex: {x: 0, y: -16, width: 16, height: 16, mask: false, label: "Unknown"},
  };
}

export function getCountryCode(identifier:string | undefined | null): FlagData {
  if (identifier) {
    const countryName = Object.values(ID_FLAG_DICTIONARY).find((countryObj) => countryObj.name === identifier);
    if (countryName) {
      return countryName;
    }
  }
  return {
    twoLetterCode: "UI",
    threeLetterCode: "UID",
    name: "Unknown",
    flagIconIndex: {x: 0, y: -16, width: 16, height: 16, mask: false, label: "Unknown"},
  };
}

export const ID_FLAG_DICTIONARY_MAP_SRC = "/flag_symbols/16pxMap.png";
