import {
    SHOW_TOAST,
    HIDE_TOAST,
    ToastAction,
} from "../actions/ToastActions";
import {IToast, UiState} from "../@types/ToastType";

export const InitialUIState: UiState = {
    toasts: [],
};

type ActionType = ToastAction | {type: ""};

export const uiReducers = (state: UiState = InitialUIState, action: ActionType): UiState => {
    switch (action.type) {
        case HIDE_TOAST:
            return {
                ...state,
                toasts: state.toasts.filter((toast: IToast) => toast.timestamp !== action.toast.timestamp),
            };
        case SHOW_TOAST:
            return {
                ...state,
                toasts: [...state.toasts, action.toast],
            };
        default:
            return state;
    }
};

export default uiReducers;
