import {MapLabels} from "../@types/PlaybookTypes";

export type MapConfig = MapLabels[];

export const LABELS_FEATURES_LIST = [
   {
     labelName: "countries",
     labelTypes: ["country-label"],
     cat: "labels",
     toggle: false,
   },
   {
     labelName: "statesCities",
     labelTypes: ["settlement-minor-label", "settlement-major-label", "state-label"],
     cat: "labels",
     toggle: false,
   },
   {
     labelName: "maritimeDomain",
     labelTypes: ["water-point-label", "water-line-label", "waterway-label"],
     cat: "labels",
     toggle: false,
   },
   {
     labelName: "landmarks",
     labelTypes: ["poi-label"],
     cat: "features",
     toggle: false,
   },
   {
     labelName: "ports",
     labelTypes: ["port-label"],
     cat: "features",
     toggle: false,
   },
   {
     labelName: "eez",
     labelTypes: ["eez", "eez-label"],
     cat: "features",
     toggle: false,
   },
   {
     labelName: "roads",
     labelTypes: ["road-motorway-trunk",
                   "road-primary",
                   "road-rail",
                   "bridge-motorway-trunk",
                   "bridge-motorway-trunk-2",
                   "bridge-major-link",
                   "bridge-major-link-2",
                   "bridge-primary-secondary-tertiary",
                   "bridge-primary-secondary-tertiary-case",
                   "bridge-street-minor-low",
                   "bridge-street-minor",
                   "road-secondary-tertiary",
                   "road-minor",
                   "road-major-link",
                   "road-number-shield",
                   "road-secondary-tertiary-case",
                   "road-motorway-trunk-case",
                   "road-major-link-case",
                   "road-primary-case",
                   "road-street",
                   "road-street-low",
                   "tunnel-motorway-trunk-case",
                   "tunnel-major-link-case",
                   "tunnel-primary-secondary-tertiary-case",
                   "tunnel-street-minor-case",
                   "tunnel-street-minor-low",
                   "tunnel-pedestrian",
                   "tunnel-steps",
                   "tunnel-path",
                   "road-label",
                   "settlement-subdivision-label",
                 ],
     cat: "features",
     toggle: false,
   },
];
