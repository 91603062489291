import * as React from "react";

interface Props {
    type: string;
    value: number;
    onChange: any;
    createTrackActivity: () => void;
}

export const OpacitySlider: React.FunctionComponent<Props> = (props: Props) => (
  <li className="opacity-slider">
    <p>{`${props.type}`}</p>
    <div>
      <input
        type="range"
        min="0"
        max="100"
        data-testid={`${props.type}-slider`}
        value={props.value * 100}
        onChange={(e) => {
                    props.onChange(parseInt(e.currentTarget.value, 10) / 100);
                    props.createTrackActivity();
                }}
      />
      <p>{`${Math.trunc(props.value * 100)}%`}</p>
    </div>
  </li>
);
