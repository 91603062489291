import * as React from "react";
import {useSelector, useDispatch} from "react-redux";
import {useQuery} from "@apollo/client";
import {useState} from "react";
import {Vessel} from "../../generated/graphql";
import Ship from "../../styles/icons/circle.svg";
import ValueDisplay from "./ValueDisplay";
import {HE_QUERY_VESSEL_CURSOR, LAST_KNOWN_SIGHTINGS} from "./query";
import IdDisplay from "../IdDisplay";
import FlagGraphics from "../FlagGraphics";
import {DATECHECK} from "../DateFilterSlider/DateCheck";
import {
    centerTimeWindow, layerVisibility,
    setFocusedVesselSelection, toggleDarkShipVisibility,
    toggleRendezvousVisibility,
    viewportChanged,
} from "../../actions/DeckMapActions";
import {RFGeoLayer} from "../../@types/Layers";
import {LastKnownPosition} from "../../@types/DeckMapTypes";
import {IApplicationState} from "../../Store";
import {QUERY_HOST} from "../../containers/ApolloContainer";
import {getLastKnownTrack} from "../../actions/TrackActions";

interface VesselRegistryDetailsProps {
    vesselInfo: Vessel;
    formatDate: (date: string) => string[];
}

interface oorStateInterface {
    outOfRange: boolean;
    lastKnownPosition: null | LastKnownPosition;
    proposedDateType: DATECHECK;
}

const VesselRegistryDetails: React.FunctionComponent<VesselRegistryDetailsProps> = ({vesselInfo, formatDate}) => {
    const [oorState, setOorState] = useState<oorStateInterface>({outOfRange: false, lastKnownPosition: null, proposedDateType: DATECHECK.INRANGE});

    const dispatch = useDispatch();
    const layers = useSelector((state: IApplicationState) => state.deck.layers);

    const {data: heQueryData, error: heQueryError, loading: heQueryLoading} = useQuery(HE_QUERY_VESSEL_CURSOR, {
        context: {clientName: QUERY_HOST.VESSEL_REGISTRY},
        variables: {mmsi: [vesselInfo.mmsi]},
        // in theory a toast would already show on previous query to the VesselCursor endpoint
        onError: (e) => console.error("Error querying vessel ", e),
    });

    const {data: lastKnown, loading: lastKnownLoading} = useQuery(LAST_KNOWN_SIGHTINGS, {
        context: {clientName: QUERY_HOST.PLAYBOOKS},
        // Skip the query if there is no mmsi or if aisLastReported has a value
        skip: !vesselInfo.mmsi || Boolean(vesselInfo.aisLastReported),
        variables: {
            mmsis: [vesselInfo.mmsi],
        },
    });

    const lastKnownHandler = (): LastKnownPosition | null => {
        let result: LastKnownPosition | null = null;

        if (vesselInfo.aisLastReported) {
            result = {
                location: vesselInfo.aisLastReported.pt,
                mmsi: vesselInfo.aisLastReported.mmsi,
                positionType: "",
                ts: vesselInfo.aisLastReported.ts,
                spireAIS: true,
            };
        }

        if (!lastKnownLoading && lastKnown) {
            const sightings = lastKnown.lastKnownSightings[0];
            if (sightings.ais !== null) result = sightings.ais[0];
            if (sightings.vhf !== null) {
                if (result === null) result = sightings.vhf[0];
                else {
                    const vhftimestamp = new Date(sightings.vhf[0].ts);
                    const resulttimestamp = new Date(result.ts);
                    if (vhftimestamp > resulttimestamp) result = sightings.vhf[0];
                    else return result;
                }
            }
            if (sightings.darkship !== null) {
                if (result === null) result = sightings.darkship[0];
                else {
                    const darkshiptimestamp = new Date(sightings.darkship[0].ts);
                    const resulttimestamp = new Date(result.ts);
                    if (darkshiptimestamp > resulttimestamp) result = sightings.darkship[0];
                    else return result;
                }
            }
            if (sightings.rendezvous !== null) {
                if (result === null) result = sightings.rendezvous[0];
                else {
                    const rendezvoustimestamp = new Date(sightings.rendezvous[0].ts);
                    const resulttimestamp = new Date(result.ts);
                    if (rendezvoustimestamp > resulttimestamp) result = sightings.rendezvous[0];
                    else return result;
                }
            }
        }

        return result;
    };

    // Function to render the values for darkships, rendezvous, counts, etc based on loading status from the heVesselCursor call
    const resolveVesselCounts = (fieldName: string): string => {
        let result = "";
        if (heQueryLoading || !heQueryData || !(heQueryData.heVesselCursor)) result = "Loading";
        else if (heQueryError || !(heQueryData.heVesselCursor.content) || heQueryData.heVesselCursor.content.length === 0) result = "Error";
        else if (heQueryData.heVesselCursor.content[0][fieldName] === null) result = "0";
        else result = heQueryData.heVesselCursor.content[0][fieldName].toString();
        return result;
    };

    function getSanctionedShipString() {
        const sanctionStrings: string[] = [];
        if (vesselInfo?.shipSanctionedEu) sanctionStrings.push("EU");
        if (vesselInfo?.shipSanctionedUn) sanctionStrings.push("UN");
        if (vesselInfo?.shipSanctionedOfac) sanctionStrings.push("OFAC");
        if (vesselInfo?.shipSanctionedOfacNonSdn) sanctionStrings.push("OFAC NONSDN");
        let result = "No";
        if (sanctionStrings.length > 0) result = sanctionStrings.join();
        return result;
    }

    const toggleLayer = (layers: RFGeoLayer[], layerType: string) => {
        const layersToCheck = ["ais", "vhf_identified"];
        switch (layerType.toUpperCase()) {
            case ("AIS"):
            case ("VHF"):
                layers.forEach((layers: RFGeoLayer) => {
                    if (layersToCheck.includes(layers.name) && !layers.visible) {
                        dispatch(layerVisibility(layers.name));
                    }
                });
                break;
            case ("DARKSHIPS"):
                dispatch(toggleDarkShipVisibility(true));
                break;
            case ("RENDEZVOUS"):
                dispatch(toggleRendezvousVisibility(true));
                break;
            default:
                throw new Error("Layer is not supported");
        }
    };

    const outOfRangeHandler = (type: DATECHECK, lastLocation: LastKnownPosition) => {
        setOorState({
            outOfRange: true,
            lastKnownPosition: lastLocation,
            proposedDateType: type,
        });
    };

    const oorClose = () => {
        setOorState({outOfRange: false, lastKnownPosition: null, proposedDateType: DATECHECK.INRANGE});
    };

    const autoAdjust = () => {
        if (oorState.lastKnownPosition) {
            dispatch(centerTimeWindow(new Date(oorState.lastKnownPosition.ts)));
            dispatch(viewportChanged({
                longitude: oorState.lastKnownPosition.location.coordinates[0],
                latitude: oorState.lastKnownPosition.location.coordinates[1],
                zoom: 10.5,
            }));
            setOorState({outOfRange: false, lastKnownPosition: null, proposedDateType: DATECHECK.INRANGE});

            if (oorState.lastKnownPosition.spireAIS) {
                dispatch(getLastKnownTrack(oorState.lastKnownPosition.mmsi, oorState.lastKnownPosition.ts));
            } else {
                toggleLayer(layers, oorState.lastKnownPosition.positionType);
                dispatch(setFocusedVesselSelection(oorState.lastKnownPosition));
            }
        }
    };

    if (oorState.outOfRange) {
        return (
          <div className="tracking-warning-container">
            <p>The timeline is not in range to view this vessel’s last known location.</p>
            <p>Click to adjust the timeline </p>
            <div className="tracking-warning-actions">
              <button
                type="button"
                className="hss-ms-text-link"
                onClick={() => oorClose()}
              >
                Back
              </button>
              <button
                type="button"
                className="hss-ms-text-link"
                onClick={() => autoAdjust()}
              >
                Auto-adjust
              </button>
            </div>
          </div>
        );
    }

    const getLastDetectedValue = (fieldName: string) => {
        if (resolveVesselCounts(fieldName) === "0" ||
            resolveVesselCounts(fieldName) === "Error") {
            return "Unknown";
        }
        return formatDate(resolveVesselCounts(fieldName));
    };

    return (
      <div className="hss-ms-vr-details-panel">
        {/* Hiding until we get actual risk score and vessel icons */}
        <div className="hss-ms-vr-details-images" style={{display: "none"}}>
          <div className="vr-risk-score high-risk">
            <h3>H</h3>
            <span>HIGH RISK </span>
          </div>
          <div className="vr-type-svg"><img alt="Vessel type" src={Ship} /></div>
        </div>
        {/* Vessel Details */}
        <div className="hss-ms-vr-details-panel-content">
          <div className="vr-details-panel-section-container">
            <div className="vr-details-panel-section col-1">
              <ValueDisplay header="Name" value={vesselInfo.name} />
              <div className="vr-value-display">
                <h3 data-testid="vr-value-header">MMSI</h3>
                {(vesselInfo.mmsi) ? (
                  <IdDisplay
                    showFavorite
                    showCountry
                    showValueType={false}
                    iconMode="always"
                    value={vesselInfo.mmsi}
                    queryValue={vesselInfo.mmsi}
                    valueType="MMSI"
                    showZoomTo
                    lastKnownPosition={lastKnownHandler()}
                    dateOutOfRangeHandler={outOfRangeHandler}
                  />
                  ) : <span>N/A</span>}
              </div>
              <ValueDisplay header="IMO" value={vesselInfo.imoNumber} />
              <div className="vr-value-display">
                <h3 data-testid="vr-value-header">Country</h3>
                <FlagGraphics mmsi={vesselInfo.mmsi} />
              </div>
              <ValueDisplay header="Call sign" value={vesselInfo.callSign} />
              <ValueDisplay header="Type 1" value={vesselInfo.shipTypeLevel2} />
              <ValueDisplay header="Type 2" value={vesselInfo.shipTypeLevel3} />
            </div>
            <div className="vr-details-panel-section col-2">
              <ValueDisplay header="Sanctioned" value={getSanctionedShipString()} />
              <ValueDisplay header="Owner" value={[vesselInfo.registeredOwner, vesselInfo.registeredOwnerCountryOfDomicile]} />
              <ValueDisplay header="Operator" value={[vesselInfo.operator, vesselInfo.operatorCountryOfControl]} />
              <ValueDisplay header="Manager" value={[vesselInfo.manager, vesselInfo.managerCountryOfControl]} />
              <ValueDisplay header="GT" value={vesselInfo.grossTonnage} />
            </div>
          </div>
          <div className="hss-ms-vr-hr-div" />
          {/* valid MMSI is required to get counts.  If we don't do this check then it would just show "Error" for each value */}
          {vesselInfo.mmsi === null ? (
            <div className="no-statistics">
              No detection statistics available.
              <span> - </span>
            </div>
          ) : (
            <div className="vr-details-panel-section-container a">
              <div className="vr-details-panel-section col-1-a">
                <ValueDisplay
                  header="Last detected"
                  value={getLastDetectedValue("vhfDscLastGeoTime")}
                />
                <ValueDisplay
                  header="Detections, past 30 days"
                  value={resolveVesselCounts("vhfDscCount")}
                />
              </div>
              <div className="vr-details-panel-section col-2-a">
                <ValueDisplay
                  header="Dark, past 30 days"
                  value={resolveVesselCounts("darkshipCount")}
                />
                <ValueDisplay
                  header="Rendezvous, past 30 days"
                  value={resolveVesselCounts("rndvzCount")}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
};

export default VesselRegistryDetails;
