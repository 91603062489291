import GL from "@luma.gl/constants";
import {RGBAColor} from "@deck.gl/core/utils/color";
import {Position} from "@deck.gl/core";
import {MVTLayer} from "@deck.gl/geo-layers";
import Tileset2D from "@deck.gl/geo-layers/tile-layer/tileset-2d";
import {TileLayerProps} from "@deck.gl/geo-layers/tile-layer/tile-layer";
import {IconLayer, PolygonLayer} from "@deck.gl/layers";
import {PathStyleExtension} from "@deck.gl/extensions";
import {RFGeoLayer} from "../../@types/Layers";
import {ResetDateRange} from "../../@types/DateRange";
import {IViewport} from "../../@types/Viewport";
import {COLOR_RANGE, COLOR_RANGE_LENGTH} from "../../@types/ColorRange";
import {
    buildTileServerURL,
    COVERAGE_MAP_ID,
    COVERAGE_MAP_ZOOM_THRESHOLD,
    MapVisualization,
} from "../../reducers/LayerManagement";
import {store} from "../../Store";

import {fetchWithToken} from "../../actions/AuthActions";

import CoverageMapDecorators from "../../styles/map_symbols/coverage_map_decorators.png";

import {coverageSquareTimeFactor} from "../../worker/coverageSquareTimeFactor";

function tileHasDarkRf(feature: any, layers: RFGeoLayer[]): boolean {
    return layers
        .filter((l) => l.visible)
        .some((l) => feature.properties[`count_dark_rf_${l.totalSuffix}`] > 0);
}

function getIconOpacity(feature: any, layers: RFGeoLayer[], layerOpacityCoverageWithId: number): number[] {
    // There are instances when the feature marker is not being updated.
    // So if the tile has 'dark rf counts' but the marker is ID then icon will be invisible
    if (tileHasDarkRf(feature, layers) && feature.properties.marker === "id") {
        return [0, 0, 0, 0];
    }
    if (feature.properties.timeFactor) {
        return [255, 255, 255, (layerOpacityCoverageWithId * 255)];
    }
    return [255, 255, 255, 0];
}

export function calculateCoverageSquarePosition(feature: any, layers: RFGeoLayer[]): Position {
    const longs: number[] = [];
    const lats: number[] = [];
    const coords = feature.geometry.coordinates;

    for (let i = 0; i < coords[0].length; i++) {
        longs.push(coords[0][i][0]);
        lats.push(coords[0][i][1]);
    }

    // the lowest y coordinate (minLat)
    // the highest y coordinate (maxLat)
    // the lowest x coordinate (minLong)
    // the highest x coordinate (maxLong)
    const maxLat = Math.max(...lats);
    const minLat = Math.min(...lats);
    const maxLong = Math.max(...longs);
    const minLong = Math.min(...longs);

    const centerX = minLong + ((maxLong - minLong) / 2);
    const centerY = minLat + ((maxLat - minLat) / 2);

    // long = 0, lat = 1
    if (tileHasDarkRf(feature, layers)) {
        return [maxLong, minLat];
    }

    return [centerX, centerY];
}

function getCoverageIconSize(f, layers: RFGeoLayer[], darkRfEnabled: boolean) {
    if (darkRfEnabled) {
        return tileHasDarkRf(f, layers) ? 20 : 18;
    }
    return 20;
}

function calculateCoverageSquareDetails(
    feature: any,
    layers: RFGeoLayer[],
    precisionThreshold: number,
    _darkshipLayerVisible: boolean,
    _rendezvousLayerVisible: boolean,
    darkRfEnabled: boolean,
) {
    let count = 0;
    let marker: string | null = null;
    for (const layer of layers) {
        if (layer.visible) {
            // Check if visible.
            // Provide a feature property
            if (layer.hasId) {
                if (layer.isSeaker) {
                    const featurePropertyName = `total_count_${layer.totalSuffix}`;
                    if (layer.totalSuffix) {
                        if (feature.properties[featurePropertyName]) {
                            marker = "seaker";
                            count += feature.properties[featurePropertyName];
                        }
                    }
                } else {
                    const featurePropertyName = precisionThreshold >= Number.MAX_SAFE_INTEGER ?
                        layer.idTotalField :
                        `count_${precisionThreshold}k_${layer.totalSuffix}`;
                    if (featurePropertyName) {
                        if (feature.properties[featurePropertyName]) {
                            marker = "id";
                            count += feature.properties[featurePropertyName];
                        }
                    }
                }
            } else {
                const featurePropertyName = precisionThreshold >= Number.MAX_SAFE_INTEGER ?
                    `total_count_${layer.totalSuffix}` :
                    `count_${precisionThreshold}k_${layer.totalSuffix}`;
                if (featurePropertyName) {
                    if (feature.properties[featurePropertyName]) {
                        count += feature.properties[featurePropertyName];
                    }
                }
            }
        }
    }

    if (darkRfEnabled) {
        marker = tileHasDarkRf(feature, layers) ? "darkRf" : marker;
    }

    feature.properties.marker = marker;
    feature.properties.totalCount = count;
}

export function calculateCoverageFillColor(feature: any, layers: RFGeoLayer[], precisionThreshold: number, resettedDateRange: ResetDateRange,
                                           sliderOpacity: number, darkshipLayerVisible: boolean, rendezvousLayerVisible: boolean,
                                           coverageMax: number, darkRfEnabled: boolean) : RGBAColor {
    // const startTimestamp = dateRange.start.getTime() / 1000;
    // const endTimestamp = dateRange.end.getTime() / 1000;
    // feature.properties.timeFactor = (endTimestamp - startTimestamp) / 86400;

    if (coverageMax) {
      feature.properties.timeFactor = coverageSquareTimeFactor(feature.properties, resettedDateRange);
      if (feature.properties.timeFactor) {
        // Check if it's within time.
        calculateCoverageSquareDetails(feature, layers, precisionThreshold, darkshipLayerVisible, rendezvousLayerVisible, darkRfEnabled);
        if (feature.properties.totalCount) {
          // Check there's actually something in there before doing expensive calculations.
          const colorIndex = Math.min(Math.ceil((feature.properties.totalCount / coverageMax) * COLOR_RANGE_LENGTH), COLOR_RANGE_LENGTH);
          if (COLOR_RANGE[colorIndex]) {
            // Color exists in the COLOR_RANGE const. Now calculate alpha.

            // If timeFactor is higher, that means longer dateRange.
            // The lowest possible opacity should be lowered if timeFactor is higher.
            const minimumAlpha = (1 / feature.properties.timeFactor) * 255;
            const alpha = Math.ceil(
              (minimumAlpha + ((255 - minimumAlpha) * (colorIndex / COLOR_RANGE_LENGTH)))
              * sliderOpacity,
            );
            return ([...COLOR_RANGE[colorIndex], alpha] as unknown) as RGBAColor;
          }
        }
      }
    }
    return [0, 0, 0, 0];
}

// Outline is transparent unless the square is selected
export function calculateCoverageOutlineColor(
    feature: any,
    tooltipDisplayFeatureIds: number[],
    dateRangeDays: number,
    identifiersList: string[],
) : RGBAColor {
    let alpha = 3;
    if (dateRangeDays < 21) {
      alpha = (1 / (dateRangeDays)) * 64;
    }
    /* if these signals ever become identifiable...
    feature.properties.ids_lband
    feature.properties.ids_sband
    feature.properties.ids_uhf_gmrs
    feature.properties.ids_xband
     */
    const result : RGBAColor = [0, 178, 255, alpha];
    if (feature.properties.timeFactor && feature.properties.totalCount) {
      if (tooltipDisplayFeatureIds.indexOf(feature.id) > -1) return [255, 0, 255, 255];
      if (identifiersList.length) {
        let idTrip = false;
        for (const idx in identifiersList) {
          if (!idTrip) {
            const identifier = identifiersList[idx];
            if (feature.properties.ids_ais && feature.properties.ids_ais.indexOf(identifier) > -1) { idTrip = true; break; }
            if (feature.properties.ids_dsc && feature.properties.ids_dsc.indexOf(identifier) > -1) { idTrip = true; break; }
            if (feature.properties.ids_darkship && feature.properties.ids_darkship.indexOf(identifier) > -1) { idTrip = true; break; }
            if (feature.properties.ids_rendezvous && feature.properties.ids_rendezvous.indexOf(identifier) > -1) { idTrip = true; break; }
            if (feature.properties.ids_epirb && feature.properties.ids_epirb.indexOf(identifier) > -1) { idTrip = true; break; }
          }
        }
        if (idTrip) return [120, 0, 255, 255];
      }
    }
    return result;
}

// Gets all coverage map features in view, filtered by date range
// Added optional filter for zyx.  Need this for finding coverage squares that coincide with a selected square
// when doing statistics on tooltip selection
//
// This function is used when calculating statistics.  If we add SEAker counts into statistics then we
// will need to account for insight visibility
export function pickVisibleCoverageSquares(deckRef:any, viewport: IViewport, resettedDateRange: ResetDateRange, zyx?: string[]) {
    // Originally we used deck.pickObjects to get all squares in the viewport.  However, that only gives a subset
    // of visible squares, the top ones on the drawing stack.  We need to do a deep pick of squares, since our data
    // is stacked by day.
    const pickedObjects = deckRef.current ? deckRef.current.pickObjects({
        x: 0,
        y: 0,
        width: viewport.width,
        height: viewport.height,
        layerIds: [COVERAGE_MAP_ID],
    }) : [];
    const result: any[] = [];
    for (const i in pickedObjects) {
      const feature = pickedObjects[i];
      const isInTimeRange = coverageSquareTimeFactor(feature.object.properties, resettedDateRange);
      if (zyx) {
          if (isInTimeRange && zyx.includes(feature.object.properties.zyx)) {
            const copiedFeature = {...feature, sourceLayer: undefined, tile: undefined, layer: {id: feature.layer.id}};
            result.push(copiedFeature);
          }
      } else if (isInTimeRange) {
            const copiedFeature = {...feature, sourceLayer: undefined, tile: undefined, layer: {id: feature.layer.id}};
            result.push(copiedFeature);
          }
    }
    return result;
}

export function generateCoverageMap(
    handleViewportLoad: Function,
    onCoverageMapClick: Function,
    currentMapVisual: MapVisualization,
    handleError: (errorMsg: string) => void,
): MVTLayer<Tileset2D, TileLayerProps<Tileset2D>> {
    const state = store.getState();
    const deckState = state.deck;
    const identifiersList = [...deckState.soiIdentifiers, ...deckState.vesselSearchMmsis];
    const resettedDateRange = deckState.dateRange.getResettedDateRange();
    // @ts-ignore
    return new MVTLayer<Tileset2D, TileLayerProps<Tileset2D>>({
        id: COVERAGE_MAP_ID,
        // //minZoom/maxZoom properties don't seem to be taking effect, so do custom visibility based on zoom level
        maxZoom: COVERAGE_MAP_ZOOM_THRESHOLD - 1,
        minZoom: 1.51,
        refinementStrategy: "no-overlap",
        visible: true,
        loadOptions: {
            fetch: (url: string) => fetchWithToken(url, state.auth.keycloak),
        },
        data: buildTileServerURL(COVERAGE_MAP_ID, deckState.dateDomain, state.deck.layers),
        onViewportLoad: (tiles: any[]) => {
            performance.mark("ms:all_map_vector_tiles_loaded");
            handleViewportLoad(tiles, "highlight");
        },
        onTileError: (error: any) => {
            console.error("Error loading tile", error);
            handleError("Error occurred in coverage map");
        },
        pickable: true,
        onClick: (event: any, info: any) => {
            onCoverageMapClick(event, info);
        },
        parameters: () => ({depthTest: false}),
        renderSubLayers: (props: any) => [
                new PolygonLayer({
                    ...props,
                    id: `${props.id}_square`,
                    _subLayerProps: {
                        // https://deck.gl/docs/api-reference/core/composite-layer
                        // https://javagl.github.io/GLConstantsTranslator/GLConstantsTranslator.html
                        stroke: {
                            getOffset: -1,
                            extensions: [new PathStyleExtension({offset: true})],
                        },
                        fill: {
                            parameters: {
                                // GL Resources https://learnopengl.com/Advanced-OpenGL/Blending, https://www.khronos.org/registry/OpenGL-Refpages/gl4/html/glBlendFuncSeparate.xhtml
                                [GL.BLEND]: true,
                                blendFunc: [GL.SRC_ALPHA, GL.ONE_MINUS_SRC_ALPHA],
                            },
                        },
                    },
                    extruded: false,
                    stroked: true,
                    getPolygon: (d: any) => d.geometry.coordinates,
                    lineWidthUnits: "pixels",
                    getFillColor: (feature: any) => calculateCoverageFillColor(feature, deckState.layers, deckState.precisionThreshold,
                        resettedDateRange, deckState.layerOpacity.coverageNoID, deckState.darkShipLayerVisible,
                        deckState.rendezvousLayerVisible, deckState.coverageMax, deckState.darkRfEnabled),
                    getLineColor: (feature: any) => calculateCoverageOutlineColor(feature, deckState.tooltipDisplayFeatureIds,
                        deckState.dateDomainRangeDays, identifiersList),
                    getLineWidth: 1,
                    updateTriggers: {
                        // updateTriggers is the secret sauce to get the deck layer to watch some value/state for change
                        getFillColor: [
                            deckState.dateRange,
                            deckState.layerOpacity.coverageNoID,
                            deckState.layers,
                            deckState.darkShipLayerVisible,
                            deckState.rendezvousLayerVisible,
                            deckState.precisionThreshold,
                            deckState.coverageMax,
                            deckState.darkRfEnabled,
                        ],
                        getLineColor: [
                            currentMapVisual,
                            deckState.dateDomainRangeDays,
                            deckState.dateRange,
                            deckState.layers,
                            deckState.darkShipLayerVisible,
                            deckState.rendezvousLayerVisible,
                            deckState.soiIdentifiers,
                            deckState.vesselSearchMmsis,
                            deckState.coverageMax,
                            deckState.tooltipDisplayFeatureIds,
                        ],
                    },
                }),
                // have to give this layer an ID different than the previous, otherwise we get assert errors - layer IDs need to be unique`
                new IconLayer({
                    ...props,
                    id: `${props.id}_hasid`,
                    loadOptions: {
                        imagebitmap: {
                            premultiplyAlpha: "none",
                        },
                    },
                    iconAtlas: CoverageMapDecorators,
                    iconMapping: {
                        id: {x: 8, y: 11, width: 12, height: 12, mask: false},
                        seaker: {x: 28, y: 11, width: 12, height: 12, mask: false},
                        darkRf: {x: 175, y: 5, width: 14, height: 14, mask: false},
                    },
                    getIcon: (feature: any) => feature.properties.marker,
                    getPosition: (f) => calculateCoverageSquarePosition(f, deckState.layers),
                    pickable: false,
                    sizeScale: 1,
                    sizeMinPixels: 12,
                    sizeUnits: "pixels",
                    getPixelOffset: (d: any) => tileHasDarkRf(d, deckState.layers) ? [0, 11] : [5, 5],
                    getSize: (d: any) => getCoverageIconSize(d, deckState.layers, deckState.darkRfEnabled),
                    getColor: (feature: any) => getIconOpacity(feature, deckState.layers, deckState.layerOpacity.coverageWithID),
                    updateTriggers: {
                        getIcon: [
                            deckState.dateRange,
                            deckState.layers,
                            deckState.precisionThreshold,
                            deckState.darkShipLayerVisible,
                            deckState.rendezvousLayerVisible,
                            deckState.coverageMax,
                        ],
                        getColor: [
                            deckState.dateRange,
                            deckState.layers,
                            deckState.darkShipLayerVisible,
                            deckState.rendezvousLayerVisible,
                            deckState.layerOpacity.coverageWithID,
                            deckState.coverageMax,
                            deckState.darkRfEnabled,
                        ],
                        getSize: [
                            deckState.darkRfEnabled,
                        ],
                    },
                }),
            ],
    });
}
