import * as React from "react";

interface MiscDetailsProps {
    mouseCoordinates: [number, number];
    zoomLevel: number;
}

const MiscDetails: React.FunctionComponent<MiscDetailsProps> = ({
    mouseCoordinates,
    zoomLevel,
}: MiscDetailsProps) => {
    const [date, setDate] = React.useState(new Date());

    React.useEffect(() => {
        const timerId = setInterval(() => {
            setDate(new Date());
        }, 1000);

        return () => clearInterval(timerId);
    }, []);

    function formatTimeStamp(date: Date): string {
        const formatted = date.toLocaleString("default", {hour12: false, timeZone: "UTC"}).split(",");
        return `UTC ${formatted[1]}, ${formatted[0]}`;
    }

    function formatCoords(coordinates: number[]): string {
        return `${coordinates[1].toFixed(5)}, ${coordinates[0].toFixed(5)}`;
    }

    return (
      <div className="MapMiscDetails">
        <div className="zoom-level">
          {`Z ${zoomLevel.toFixed(2).toString()}`}
        </div>
        <div className="MapTimestampPos">
          <span className="MapPos">
            {`C ${mouseCoordinates && mouseCoordinates.length ? formatCoords(mouseCoordinates) : null}`}
          </span>
          <span className="MapTimestamp">{formatTimeStamp(date)}</span>
        </div>
      </div>
    );
};

export default MiscDetails;
