/*
 Code used by MapReducer to manage layers.  Pulled out of MapReducer.ts to make it more manageable
 */
import {cloneDeep} from "lodash";
import {RFGeoLayer} from "../@types/Layers";
import {DateRange} from "../@types/DateRange";
import Config from "../Config";
// Constants for use where we need to check values associated with the coverage map to know if it is displayed or do queries
export const COVERAGE_MAP_ZOOM_THRESHOLD = 10; // what zoom level we switch between coverage map and point layers
export const COVERAGE_MAP_ID = "coverage_map"; // name of the coverage map to use in styling/layer detection
// In the database function we apply a fixed zoom factor to make the resulting polygoons smaller
// so the Z property in the feature is off by this much from the view zoom level
export const COVERAGE_MAP_ZOOM_FACTOR = 5;
export const MAP_OVERRIDE_THRESHOLD = 5.8;
export enum MapVisualization {
    CoverageMap = "COVERAGE_MAP",
    IconLayer = "ICON_LAYER",
}

export function buildTileServerURL(layerName: string, dateDomain: DateRange, layers?: RFGeoLayer[]) {
    let result = `${Config.strataHost}/he_map/${layerName}/{z}/{x}/{y}.mvt`;
    const startDate = dateDomain.start.toISOString().split("T")[0];
    const endDate = dateDomain.end.toISOString().split("T")[0];
    result += `?between=${startDate},${endDate}`;
    if (layerName === COVERAGE_MAP_ID && layers?.length) {
        const nonVisibleSeakerLayers = layers.filter(({visible, isSeaker}) => !visible && isSeaker);
        nonVisibleSeakerLayers.forEach(({name}) => {
            result += `&excluded_layers[]=${name}`;
        });
    }
    return result;
}

export function buildCoverageMapExpression(layers: RFGeoLayer[], idsOnly: boolean) {
    const result:any = ["+"];
    layers.forEach((layer) => {
        if (layer.visible) {
            const coverageFieldName = `total_${layer.totalSuffix}`; // TODO: do we need precision threshold here
            if (idsOnly) {
                if (layer.hasId) {
                    result.push(["get", layer.idTotalField]);
                }
            } else {
                result.push(["get", coverageFieldName]); // TODO: can we combine vhf_unidentified and vhf_dsc layers, differentiate here and use iconLayer logic if there is an ID field to adjust symbology?
            }
        }
    });
    return result;
}

export function updateMapLabelLayers(currentMapStyle: any, labelTypes: string | string[], mode : string) {
    const cloneMapStyle = cloneDeep(currentMapStyle);

    // Going through the current mapStyle and finding any layer that matches the labelTypes passed in.
    // Once Layer is found the visibility is toggled.
    const updatedMapStyle = cloneMapStyle.layers.map((layer: any) => {
        if (labelTypes.includes(layer.id)) {
            return {
              ...layer,
              layout: {
                ...layer.layout,
                visibility: mode,
              },
            };
        }

        return layer;
    });

    return updatedMapStyle;
}
