import React from "react";
import {useDispatch, useSelector} from "react-redux";
import polygon from "../../styles/icons/polygon.svg";
import {IApplicationState} from "../../Store";
import {viewportChanged} from "../../actions/DeckMapActions";
import {getAnnotationIcon} from "../Layers/EditableGeoLayer";
import {OverflowEllipsis} from "../OverflowEllipsis";

export interface SideAnnotationsPanelProps {
    allAnnotations: any[];
    selectedAnnotation: any;
    selectAnnotation: (annotation: any) => void;
    updateAnnotation: (annotation: any) => void;
    createAnnotationTracking: () => void;
}

export const SideAnnotationsPanel: React.FunctionComponent<SideAnnotationsPanelProps> =
    ({allAnnotations, selectedAnnotation, selectAnnotation, updateAnnotation, createAnnotationTracking}: SideAnnotationsPanelProps) => {
    const [expanded, setExpanded] = React.useState<boolean>(true);

    const dispatch = useDispatch();
    const currentViewport = useSelector((state: IApplicationState) => state.deck.viewport);

    const handleAnnotationLocking = (annotation: any) => {
        const updatedAnnotation = {
            ...annotation,
            properties: {...annotation.properties, locked: !annotation.properties.locked},
        };
        updateAnnotation(updatedAnnotation);
        createAnnotationTracking();
    };

    const handleAnnotationVisibility = (annotation: any) => {
        const updatedAnnotation = {
            ...annotation,
            properties: {...annotation.properties, hidden: !annotation.properties.hidden},
        };
        updateAnnotation(updatedAnnotation);
        createAnnotationTracking();
    };

    const handleZoomTo = (annotation: any) => {
        if (annotation.properties.view) {
            const {zoom, longitude, latitude} = annotation.properties.view;
            const mergedViewport = {...currentViewport, zoom, longitude, latitude};
            dispatch(viewportChanged(mergedViewport));
            selectAnnotation(annotation);
        } else {
            console.error("No coordinates were saved with annotation. Please delete and re-draw. ");
        }
    };

    const renderAnnotationsContent = () => {
        if (!allAnnotations.length) {
            return <div className="no-annotations">No shapes created</div>;
        }

        return allAnnotations.map((annotation, idx) => (
          <div
            key={idx}
            className={`focused-annotation-container 
                ${annotation === selectedAnnotation && "selected-annotation"}`}
          >
            <div className="focused-annotation-icon">
              <img alt="Annotation Type" src={getAnnotationIcon(annotation)} />
            </div>
            <p className="focused-annotation-name" onClick={() => selectAnnotation(annotation)}>
              <OverflowEllipsis>{annotation.name ? annotation.name : "Unnamed Object"}</OverflowEllipsis>
            </p>
            <div className="focused-annotation-selectors">
              <div
                className="icon-zoom-2 floating-icon"
                onClick={() => handleZoomTo(annotation)}
              />
              {annotation !== selectedAnnotation && (
                <>
                  <div
                    className={`${annotation.properties.hidden ? "icon-unhide" : "icon-hide"} floating-icon`}
                    onClick={() => handleAnnotationVisibility(annotation)}
                  />
                  <div
                    className={`${annotation.properties.locked ? "icon-locked" : "icon-unlocked"} floating-icon`}
                    onClick={() => handleAnnotationLocking(annotation)}
                  />
                </>
              )}
            </div>
          </div>
        ));
    };

    return (
      <div className="floating-panel-main">
        <div className="floating-panel-content hss-scrollbar">
          <div className="floating-category-header" onClick={() => setExpanded(!expanded)}>
            <div className={`floating-icon ${expanded ? "icon-pb-close" : "icon-pb-open"}`} />
            <p>Shapes & geofences</p>
          </div>
          <div className="floating-panel-list">
            <div className="floating-category-option" style={{display: "none"}}>
              <div className="floating-icon icon-upload" />
              <p>Upload Boundary</p>
            </div>
            {expanded && renderAnnotationsContent()}
          </div>
        </div>
      </div>
    );
};
