import * as React from "react";
import axios from "axios";
import Config from "../../Config";

interface IProps {
    apiKey: string;
    fireToast: any;
}

const Changelog: React.FunctionComponent<IProps> = (props: IProps) => {
    const [changeLogs, setChangeLogs] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        axios
            .get(`${Config.apiUrl}/about/changelog`, {
                headers: {Authorization: `Bearer ${props.apiKey}`},
            })
            .then((response) => {
                setChangeLogs(response.data.content);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                props.fireToast("Failed to load change logs from the database, please check your network or contact your system administrator",
                    "Error Loading Logs", "error");
                setIsLoading(false);
            });
    }, [props]);

    const getWrittenDate = (isoDate: string) => {
        const newDate = new Date(isoDate);
        const formatted = newDate.toLocaleString("default", {year: "numeric", month: "long", day: "numeric", timeZone: "UTC"});
        return formatted;
    };

    const generateLogs = () => {
        if (changeLogs.length) {
            return changeLogs.map((log: any, index) => (
              <div className="change-log" key={index}>
                <h2>{getWrittenDate(log.releaseDate)}</h2>
                <h3>{log.title}</h3>
                <div>{log.description}</div>
              </div>
                ));
        }
            return <div>No Logs In Database</div>;
    };

    return (
      <div className="hss-ms-changelog-container hss-ms-flex-col">
        <div className="hss-ms-h2">Changelog</div>
        <div className="hss-ms-hr-div" />

        <div className="changelog-container hss-scrollbar">
          {isLoading ? (
            <div className="hss-loader"><div /></div>
                ) : generateLogs() }
        </div>
      </div>
    );
};

export default Changelog;
