import * as React from "react";

interface IProps {
    rangeStart: Date,
    rangeEnd: Date
}

export function SliderToolTip({rangeStart, rangeEnd}: IProps) {
    const options = {year: "numeric", month: "numeric", day: "numeric"};
    const startDate = new Date(rangeStart); const
endDate = new Date(rangeEnd);
    const hours = Math.round(Math.abs(startDate.getTime() - endDate.getTime()) / 3600000);

    return (
      <div>
        <div className="hours">
          {`${hours} Hours`}
          {" "}
          range
        </div>
        <div>
          {`${startDate.toLocaleDateString("UTC", options)}
                - ${endDate.toLocaleDateString("UTC", options)}`}
        </div>
      </div>
    );
}
