/* import * as React from "react"; */
import React from "react";
import "../styles/LegendPanel.scss";
import {useSelector} from "react-redux";
import {useTreatments} from "@splitsoftware/splitio-react";
import {ICON_MAP} from "./iconMap";
import {IApplicationState} from "../Store";

const LegendPanel = () => {
    const legendItems: any = [];
    const iconMapStyle = useSelector((state: IApplicationState) => state.deck.iconMapType);
    const iconBackground = iconMapStyle === "SIMPLE" ? 'url("/map_symbols/1x/iconmap1x.png")' : 'url("/map_symbols/1x/new_iconmap_1x.png")';
    const layers = useSelector((state: IApplicationState) => state.deck.layers);
    const splitNames: string[] = [];
    for (const layer of layers) {
        if (layer.splitName) {
            splitNames.push(layer.splitName);
        }
    }
    const treatments = useTreatments(splitNames);

    for (const value of Object.values(ICON_MAP)) {
        if (!value.hasOwnProperty("legend")) {
            let splitTreatment;
            if ("rfGeoLayer" in value) {
                const targetLayer = layers.find((layer) => layer.name === value.rfGeoLayer);
                splitTreatment = targetLayer?.splitName ? treatments[targetLayer.splitName] : undefined;
            }

            if (splitTreatment?.treatment !== "off") {
                legendItems.push(
                  <li key={`${value.label}-legend`}>
                    <div className="legend-img-container">
                      <div
                        className="legend-img"
                        style={{
                                    backgroundImage: iconBackground,
                                    backgroundPosition: `-${value.x}px -${value.y}px`,
                                    height: value.height,
                                    width: value.width,
                                }}
                      />
                    </div>
                    <div className="legend-label">{value.label}</div>
                  </li>,
                );
            }
        }
    }
    return (
      <div className="legend-container">
        <ul className="legend-content hss-scrollbar">
          {legendItems}
        </ul>
      </div>
    );
};

export default LegendPanel;
