import React, {useMemo, useState} from "react";
import {
    GetHandleProps,
} from "react-compound-slider";
import {SliderToolTip} from "./SliderToolTip";
import {DateRange} from "../../@types/DateRange";
import {SPREAD_THRESHOLD} from "./DateFilterSlider";

interface HandleItem {
    id: string;
    percent: number;
    value: number;
}

interface HandleProps {
    handle: HandleItem;
    getHandleProps: GetHandleProps;
    dateRange: DateRange;
    dateDomain: DateRange;
    checkAutoAdvance?: any;
    getHandleId: any;
}

export const Handle: React.FunctionComponent<HandleProps> = ({
  handle,
  getHandleProps,
  dateRange,
  dateDomain,
  checkAutoAdvance,
  getHandleId,
}) => {
  const [mouseOver, setMouseOver] = useState(false);

  const compoundSliderProps = getHandleProps(handle.id);

  const onMouseDown = (evt: React.MouseEvent) => {
    compoundSliderProps.onMouseDown(evt);
    checkAutoAdvance(handle.id);
    getHandleId(handle.id);
  };

  let percentage = 0;
  const domainStartTimestamp = dateDomain.start.getTime();
  const domainSpan = dateDomain.end.getTime() - domainStartTimestamp;
  if (handle.id === "$$-0") {
    percentage = Math.min(((dateRange.start.getTime() - domainStartTimestamp) / domainSpan), (1 - SPREAD_THRESHOLD));
    if (percentage < 0) percentage = 0;
  } else {
    percentage = Math.max(((dateRange.end.getTime() - domainStartTimestamp) / domainSpan), SPREAD_THRESHOLD);
    if (percentage > 1) percentage = 1;
  }
  percentage *= 100;

  return (
    <>
      <div
        className={`slider-tooltip ${mouseOver ? "" : "hss-ms-invisible"}`}
        style={{left: `${percentage - 10}%`}}
      >
        <SliderToolTip rangeStart={dateRange.start} rangeEnd={dateRange.end} />
      </div>
      <div
        style={{left: `${percentage}%`}}
        className="hss-ms-timeline-slider-controller-start hss-ms-timeline-slider-controller-element"
        {...compoundSliderProps}
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        onMouseDown={onMouseDown}
      >
        <div className="hss-timeline-slider-controller-rod" />
        <div className="hss-timeline-slider-controller-block" />
      </div>
    </>
  );
};
