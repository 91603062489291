import * as React from "react";
import {ScaleControl} from "react-map-gl";
import {IViewport} from "../@types/Viewport";
import "../styles/MapPage.scss";

interface IProps {
    // eslint-disable-next-line react/no-unused-prop-types
    viewport: IViewport;
    uiPanelsVisible: boolean;
}

const MapInfo = (props: IProps) => (
  <>
    <div className={`MapInfoContainer ${props.uiPanelsVisible ? "" : "hidden"}`}>
      <div className="MapScale">
        <ScaleControl unit="metric" />
      </div>
    </div>
  </>
);

export default MapInfo;
