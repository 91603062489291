/* eslint-disable react/prop-types */
import React from "react";
import "../../styles/VesselRegistry.scss";

interface IProps {
    header: string;
    value: Element | string | number | undefined | null | (Element | string | number | undefined | null)[];
}

const ValueDisplay: React.FunctionComponent<IProps> = ({header, value}) => {
    const [tooltipVisible, setTooltipVisible] = React.useState(false);
    let valueStr = "";
    if (Array.isArray(value)) {
      const filteredValue = value.filter((el) => el !== null && el !== undefined && (typeof el === "string" && el.length > 0));
      valueStr = filteredValue.length ? filteredValue.join(", ") : "N/A";
    } else {
      valueStr = value ? value.toString() : "N/A";
    }

    const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
        // Checking if text is overflowing
        // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollWidth
        if (e.currentTarget.scrollWidth > e.currentTarget.clientWidth) {
            setTooltipVisible(true);
        }
    };

    // TODO: Ideally this would use the `<OverflowEllipsis />` component, but
    // because the value is being set through `dangerouslySetInnerHTML`, makes
    // it somewhat infeasiable to do it there.
    return (
      <>
        <div className="vr-value-display">
          <h3 data-testid="vr-value-header" className={`${!header ? "hidden" : ""}`}>{header}</h3>
          <div
            className="vr-value-content"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={() => setTooltipVisible(false)}
            dangerouslySetInnerHTML={{__html: valueStr}}
          />
        </div>
        {tooltipVisible && (
          <div
            id="vr-details-tool-tip"
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
            dangerouslySetInnerHTML={{__html: valueStr}}
          />
          )}
      </>

    );
};

export default ValueDisplay;
