/* eslint-disable react/prop-types */
import * as React from "react";
import {
    useVesselSearchQuery,
    Vessel,
    VesselSearchQueryVariables,
} from "../../generated/graphql";
import FlagGraphics from "../FlagGraphics";
import ValueDisplay from "./ValueDisplay";

interface IProps {
    searchParams: VesselSearchQueryVariables;
    searchString: string;
    selectVessel: (vessel: Vessel) => void;
    formatDate: (date: string) => string[];
    autoSelect: boolean;
    handleSettingMmsis: Function;
    fireToast: Function;
}

const VesselSearchResults: React.FunctionComponent<IProps> = ({
    searchParams,
    searchString,
    selectVessel,
    formatDate,
    autoSelect,
    handleSettingMmsis,
    fireToast,
}) => {
    const {data, loading, error, called} = useVesselSearchQuery({
        context: {clientName: "vesselRegistry"},
        variables: searchParams,
        onCompleted: (data) => {
            if (data.vesselCursor?.content?.length === 1 && autoSelect) {
               if (data.vesselCursor.content[0] !== null) {
                   selectVessel(data.vesselCursor.content[0]);
               }
            }
            if (data.vesselCursor?.content?.length) {
                const mmsiResults = data.vesselCursor.content
                    .filter((v) => v!.mmsi)
                    .map((v) => v!.mmsi);
                handleSettingMmsis(mmsiResults);
            } else if (searchParams.mmsi) {
              handleSettingMmsis([searchParams.mmsi]);
            }
        },
        onError: (() => fireToast("Error occurred searching for the vessel. Please check your network and try again.",
            "Unable to search vessel", "error")),
    });

    const searchHighlight = (searchTerm: string, displayString: string) => {
        if (displayString) {
            const stringSplit = searchTerm.toLowerCase().split(displayString.toLowerCase());
            const returnString = stringSplit.join(displayString.bold());
            return returnString.toUpperCase();
        }
        return searchTerm.toUpperCase();
    };

    const vesselContentRow = (vessel: Vessel) => (
      <li
        className="vr-results-content-row"
        key={vessel.id as string}
        onClick={() => selectVessel(vessel)}
      >
        <ValueDisplay
          header=""
          value={searchHighlight(vessel.name as string, searchString)}
        />
        <div>{vessel.imoNumber ? `${vessel.imoNumber}` : "N/A"}</div>
        <div>{vessel.mmsi ? `${vessel.mmsi}` : "N/A"}</div>
        <FlagGraphics mmsi={vessel.mmsi} />
        <div>{vessel.vhfDscLastGeoTime ? formatDate(vessel.vhfDscLastGeoTime) : "Unknown"}</div>
      </li>
    );

    if (loading) {
        return (
          <div className="hss-loader-container">
            <div className="hss-loader"><div /></div>
          </div>
        );
    }

    if (!error && data && data.vesselCursor && data.vesselCursor.content && data.vesselCursor.content.length > 0 && called) {
        return (
          <div className="vr-search-results-grid-table">
            <div className="vr-results-content-header">
              <div>Vessel name</div>
              <div>IMO</div>
              <div>MMSI</div>
              <div>Flag</div>
              <div>Last detected</div>
            </div>
            <ul className="vr-results-row-container hss-scrollbar">
              {data.vesselCursor.content.map(
                  (vessel) => vessel && vesselContentRow(vessel),
              )}
            </ul>
          </div>
        );
    }

    if (error) {
        return <div className="vr-no-results">Error occurred searching in Vessel Registry.</div>;
    }

    return (
        // Will only hit this when the loading stops and no results are found.
      <div className="vr-no-results">No entry found in Vessel Registry.</div>
    );
};

export default VesselSearchResults;
