import {Reducer} from "redux";
import {IAuthState, AuthActions, AuthActionTypes} from "../@types/AuthTypes";

export const InitialAuthState: IAuthState = {
    keycloak: null,
    authenticated: false,
    username: "",
};

export const authReducer:Reducer<IAuthState, AuthActions> = (state = InitialAuthState, action) => {
    switch (action.type) {
        case AuthActionTypes.AUTHENTICATED: {
            return ({
                ...state,
                username: action.username,
                authenticated: action.keycloak.authenticated,
                keycloak: action.keycloak,
                lastAuthorized: Date.now(),
            });
        }
        case AuthActionTypes.RENEWTOKEN: {
            // Updated keycloak object, updated the stored token and clone it into the state
            const newState = {...state, keycloak: action.keycloak, lastAuthorized: Date.now()};
            return newState;
        }
        case AuthActionTypes.LOGOUT: {
            // TODO: any other mutation needed?  Call keycloak.clearToken?
            state.keycloak.logout();
            return {...state, authenticated: false};
        }
        default: {
            return state;
        }
    }
};
