export const ICON_MAP_DOUBLE = {
  ais: {x: 12, y: 14, width: 32, height: 32, mask: false, label: "AIS", rfGeoLayer: "ais"},
  ais_dark: {x: 12, y: 155, width: 32, height: 32, mask: false, label: "Dark ship", rfGeoLayer: "ais"},
  ais_dark_b: {x: 108, y: 155, width: 32, height: 32, mask: false, label: "Dark ship", legend: false},
  alert_inRange: {x: 58, y: 215, width: 32, height: 32, mask: false, label: "Alert"},
  alert_inDomain: {x: 12, y: 215, width: 32, height: 32, mask: false, label: "Alert, outside range"},
  alert_outOfDomain: {x: 102, y: 215, width: 32, height: 32, mask: false, label: "Alert, outside domain"},
  epirb: {x: 196, y: 14, width: 32, height: 32, mask: false, label: "EPIRB", rfGeoLayer: "epirb"},
  epirbT: {x: 196, y: 58, width: 32, height: 32, mask: false, label: "EPIRB, test", rfGeoLayer: "epirb"},
  lband: {x: 12, y: 58, width: 32, height: 32, mask: false, label: "L-Band", rfGeoLayer: "lband"},
  rdvz_45: {x: 436, y: 142, width: 52, height: 52, mask: false, label: "Rendezvous", legend: false},
  rdvz_90: {x: 156, y: 154, width: 62, height: 32, mask: false, label: "Rendezvous", rfGeoLayer: "rendezvous"},
  rdvz_90_b: {x: 234, y: 154, width: 62, height: 32, mask: false, label: "Rendezvous", legend: false},
  rdvz_270: {x: 312, y: 139, width: 52, height: 52, mask: false, label: "Rendezvous", legend: false},
  rdvz_360: {x: 380, y: 133, width: 40, height: 64, mask: false, label: "Rendezvous", legend: false},
  sband: {x: 150, y: 58, width: 32, height: 32, mask: false, label: "S-Band", rfGeoLayer: "sband"},
  uhf: {x: 242, y: 58, width: 32, height: 32, mask: false, label: "UHF", rfGeoLayer: "uhf"},
  vhf_identified: {x: 104, y: 14, width: 32, height: 32, mask: false, label: "VHF w/ID", rfGeoLayer: "vhf"},
  vhf_unidentified: {x: 104, y: 58, width: 32, height: 32, mask: false, label: "VHF", rfGeoLayer: "vhf"},
  xband_radar: {x: 288, y: 58, width: 32, height: 32, mask: false, label: "X-Band", rfGeoLayer: "xband"},
  rdvz_small: {x: 184, y: 224, width: 24, height: 12, mask: false, label: "Rendezvous", legend: false, insights: true},
  ais_dark_small: {x: 222, y: 218, width: 24, height: 24, mask: false, label: "Dark ship", legend: false, insights: true},
  heatmap: {x: 266, y: 216, width: 24, height: 24, mask: false, label: "Heatmap grid", legend: false, insights: false},
  heatmapId: {x: 306, y: 216, width: 24, height: 24, mask: false, label: "Heatmap grid", legend: false, insights: false},
  heatmapBv: {x: 346, y: 216, width: 24, height: 24, mask: false, label: "Heatmap grid", legend: false, insights: false},
  id_square: {x: 430, y: 222, width: 12, height: 12, mask: false, label: "ID symbol", legend: false, insights: false},
  bv_square: {x: 464, y: 222, width: 12, height: 12, mask: false, label: "Behavior symbol", legend: false, insights: false},
  aisc_track_end: {x: 148, y: 220, width: 20, height: 20, mask: true, label: "AIS correlation track, end", legend: false, insights: false},
  heatmapDarkRf: {x: 492, y: 216, width: 24, height: 24, mask: false, label: "Heatmap DarkRf", legend: false, insights: false},
  heatmapIdDarkRf: {x: 528, y: 216, width: 24, height: 24, mask: false, label: "Heatmap Id DarkRf", legend: false, insights: false},
  heatmapBVDarkRf: {x: 564, y: 216, width: 24, height: 24, mask: false, label: "Heatmap Seaker DarkRf", legend: false, insights: false},
  terrestrial_registry: {x: 372, y: 64, width: 20, height: 20, mask: false, label: "Terrestrial Registry", insights: true, rfGeoLayer: "stationary_emitter"},
  gps_interference: {x: 334, y: 64, width: 32, height: 32, mask: false, label: "GPS Interference", rfGeoLayer: "gps_interference"},

};

export const ICON_MAP_DARK_RF = {
  ais: {x: 12, y: 14, width: 32, height: 32, mask: false, label: "AIS"},
  epirb: {x: 196, y: 100, width: 32, height: 32, mask: false, label: "EPIRB"},
  lband: {x: 14, y: 100, width: 32, height: 32, mask: false, label: "L-Band"},
  sband: {x: 150, y: 100, width: 32, height: 32, mask: false, label: "S-Band"},
  uhf: {x: 242, y: 100, width: 32, height: 32, mask: false, label: "UHF"},
  // Todo will both VHFs have the same icons?
  vhf_identified: {x: 104, y: 100, width: 32, height: 32, mask: false, label: "VHF w/ID"},
  vhf_unidentified: {x: 104, y: 100, width: 32, height: 32, mask: false, label: "VHF"},
  xband_radar: {x: 288, y: 100, width: 32, height: 32, mask: false, label: "X-Band"},
};

export const ICON_MAP_DOUBLE_SRC = "/map_symbols/2x/iconmap2x.png";
export const DETAILED_ICON_DOUBLE_SRC = "/map_symbols/2x/new_iconmap_2x.png";

export const ICON_MAP = {
  lband: {x: 6, y: 29, width: 16, height: 16, mask: false, label: "L-Band", rfGeoLayer: "lband"},
  sband: {x: 75, y: 29, width: 16, height: 16, mask: false, label: "S-Band", rfGeoLayer: "sband"},
  xband_radar: {x: 144, y: 29, width: 16, height: 16, mask: false, label: "X-Band", rfGeoLayer: "xband"},
  epirb: {x: 98, y: 7, width: 16, height: 16, mask: false, label: "EPIRB", rfGeoLayer: "epirb"},
  epirbT: {x: 98, y: 29, width: 16, height: 16, mask: false, label: "EPIRB, test", rfGeoLayer: "epirb"},
  vhf_unidentified: {x: 52, y: 29, width: 16, height: 16, mask: false, label: "VHF", rfGeoLayer: "vhf"},
  vhf_identified: {x: 52, y: 7, width: 16, height: 16, mask: false, label: "VHF w/ID", rfGeoLayer: "vhf"},
  uhf: {x: 121, y: 29, width: 16, height: 16, mask: false, label: "UHF", rfGeoLayer: "uhf"},
  ais: {x: 6, y: 7, width: 16, height: 16, mask: false, label: "AIS", rfGeoLayer: "ais"},
  ais_dark: {x: 6, y: 77, width: 16, height: 16, mask: false, label: "Dark ship", rfGeoLayer: "ais"},
  ais_dark_b: {x: 6, y: 77, width: 16, height: 16, mask: false, label: "Dark ship", legend: false},
  alert_inRange: {x: 29, y: 108, width: 16, height: 16, mask: false, label: "Alert"},
  alert_inDomain: {x: 6, y: 108, width: 16, height: 16, mask: false, label: "Alert, outside range"},
  alert_outOfDomain: {x: 51, y: 108, width: 16, height: 16, mask: false, label: "Alert, outside domain"},
  rdvz_45: {x: 131, y: 68, width: 26, height: 26, mask: false, label: "Rendezvous", legend: false},
  rdvz_90: {x: 78, y: 77, width: 31, height: 16, mask: false, label: "Rendezvous", rfGeoLayer: "rendezvous"},
  rdvz_90_b: {x: 117, y: 77, width: 31, height: 16, mask: false, label: "Rendezvous", legend: false},
  rdvz_270: {x: 53, y: 68, width: 26, height: 26, mask: false, label: "Rendezvous", legend: false},
  rdvz_360: {x: 95, y: 65, width: 20, height: 32, mask: false, label: "Rendezvous", legend: false},
  rdvz_small: {x: 152, y: 41, width: 12, height: 6, mask: false, label: "Rendezvous", legend: false, insights: true},
  ais_dark_small: {x: 78, y: 77, width: 12, height: 12, mask: false, label: "Dark ship", legend: false, insights: true},
  heatmap: {x: 200, y: 39, width: 12, height: 12, mask: false, label: "Heatmap grid", legend: false, insights: false},
  heatmapId: {x: 223, y: 39, width: 12, height: 12, mask: false, label: "Heatmap grid", legend: false, insights: false},
  heatmapBv: {x: 245, y: 39, width: 12, height: 12, mask: false, label: "Heatmap grid", legend: false, insights: false},
  id_square: {x: 226, y: 78, width: 6, height: 6, mask: false, label: "ID symbol", legend: false, insights: false},
  bv_square: {x: 248, y: 78, width: 6, height: 6, mask: false, label: "Behavior symbol", legend: false, insights: false},
  aisc_track_end: {x: 54, y: 104, width: 10, height: 10, mask: true, label: "AIS correlation track, end", legend: false, insights: false},
  terrestrial_registry: {x: 183, y: 29, width: 16, height: 16, mask: false, label: "Terrestrial Registry", insights: true, rfGeoLayer: "stationary_emitter"},
  gps_interference: {x: 164, y: 29, width: 16, height: 16, mask: false, label: "GPS Interference", rfGeoLayer: "gps_interference"},
};

export const ICON_MAP_SRC = "/map_symbols/1x/iconmap1x.png";
