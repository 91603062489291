/* eslint-disable import/no-duplicates */
import gql from "graphql-tag";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

export type Maybe<T> = T | null;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  GeoJson: any;
  Instant: any;
};

export type AisClassACursorPage = {
  __typename?: "AisClassACursorPage";
  content?: Maybe<Array<Maybe<ClassAPosition>>>;
  vessel?: Maybe<Vessel>;
  pageInfo: PageInfo;
};

export type AisClassBCursorPage = {
  __typename?: "AisClassBCursorPage";
  content?: Maybe<Array<Maybe<ClassBPosition>>>;
  vessel?: Maybe<Vessel>;
  pageInfo: PageInfo;
};

export type AisEllipses = {
  __typename?: "AisEllipses";
  id?: Maybe<Scalars["ID"]>;
  passGroupId?: Maybe<Scalars["String"]>;
  downlinkedAt?: Maybe<Scalars["Instant"]>;
  createdAt?: Maybe<Scalars["Instant"]>;
  soi?: Maybe<Scalars["String"]>;
  constellation?: Maybe<Scalars["String"]>;
  mmsi?: Maybe<Scalars["String"]>;
  receivedAt?: Maybe<Scalars["Instant"]>;
  reportedPosition?: Maybe<Scalars["GeoJson"]>;
  semiMajor?: Maybe<Scalars["Float"]>;
  semiMinor?: Maybe<Scalars["Float"]>;
  orientation?: Maybe<Scalars["Float"]>;
  detectedPosition?: Maybe<Scalars["GeoJson"]>;
  frequency?: Maybe<Scalars["String"]>;
  numBursts?: Maybe<Scalars["Int"]>;
  nmeaMessage?: Maybe<Scalars["String"]>;
  ellipse?: Maybe<Scalars["GeoJson"]>;
  ellipseArea?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["String"]>;
};

export type AisVoyageCursorPage = {
  __typename?: "AisVoyageCursorPage";
  content?: Maybe<Array<Maybe<Voyage>>>;
  vessel?: Maybe<Vessel>;
  pageInfo: PageInfo;
};

export type ClassAPosition = {
  __typename?: "ClassAPosition";
  mmsi: Scalars["String"];
  ts: Scalars["Instant"];
  positionType?: Scalars["String"];
  pt?: Maybe<Scalars["GeoJson"]>;
  courseOverGround?: Maybe<Scalars["Float"]>;
  highAccuracyPos?: Maybe<Scalars["Boolean"]>;
  msgType?: Maybe<Scalars["Int"]>;
  navStatus?: Maybe<Scalars["Int"]>;
  navStatusString?: Maybe<Scalars["String"]>;
  repeatIndicator?: Maybe<Scalars["Int"]>;
  rateOfRotation?: Maybe<Scalars["Int"]>;
  speedOverGround?: Maybe<Scalars["Float"]>;
  specialManIndicator?: Maybe<Scalars["Int"]>;
  specialManIndicatorString?: Maybe<Scalars["String"]>;
  trueHeading?: Maybe<Scalars["Int"]>;
};

export type ClassBPosition = {
  __typename?: "ClassBPosition";
  mmsi: Scalars["String"];
  ts: Scalars["Instant"];
  pt?: Maybe<Scalars["GeoJson"]>;
  classbBandFlag?: Maybe<Scalars["Int"]>;
  classbDisplayFlag?: Maybe<Scalars["Int"]>;
  classbDscFlag?: Maybe<Scalars["Int"]>;
  classbMsg22Flag?: Maybe<Scalars["Int"]>;
  classbUnitFlag?: Maybe<Scalars["Int"]>;
  courseOverGround?: Maybe<Scalars["Float"]>;
  commState?: Maybe<Scalars["Int"]>;
  commStateselectorFlag?: Maybe<Scalars["Int"]>;
  highAccuracyPos?: Maybe<Scalars["Boolean"]>;
  modeFlag?: Maybe<Scalars["Int"]>;
  msgType?: Maybe<Scalars["Int"]>;
  repeatIndicator?: Maybe<Scalars["Int"]>;
  speedOverGround?: Maybe<Scalars["Float"]>;
  trueHeading?: Maybe<Scalars["Int"]>;
};

export type HeVhfCursorPage = {
  __typename?: "HEVhfCursorPage";
  content?: Maybe<Array<Maybe<VhfEllipses>>>;
  vessel?: Maybe<Vessel>;
  pageInfo: PageInfo;
};

export type HeAisCursorPage = {
  __typename?: "HeAISCursorPage";
  content?: Maybe<Array<Maybe<AisEllipses>>>;
  vessel?: Maybe<Vessel>;
  pageInfo: PageInfo;
};

export type PageInfo = {
  __typename?: "PageInfo";
  hasNext: Scalars["Boolean"];
  nextCursor?: Maybe<Scalars["String"]>;
};

export type Query = {
  __typename?: "Query";
  vesselCursor?: Maybe<VesselCursorPage>;
  heVesselCursor?: Maybe<VesselCursorPage>;
  heAisGeoCursor?: Maybe<HeAisCursorPage>;
  heVhfDscGeoCursor?: Maybe<HeVhfCursorPage>;
  aisClassACursor?: Maybe<AisClassACursorPage>;
  aisClassBCursor?: Maybe<AisClassBCursorPage>;
  aisVoyageCursor?: Maybe<AisVoyageCursorPage>;
};

export type QueryVesselCursorArgs = {
  mmsi?: Maybe<Array<Maybe<Scalars["String"]>>>;
  imoNumber?: Maybe<Array<Maybe<Scalars["String"]>>>;
  matchName?: Maybe<Scalars["String"]>;
  matchCallSign?: Maybe<Scalars["String"]>;
  limit?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
};

export type QueryHeVesselCursorArgs = {
  mmsi?: Maybe<Array<Maybe<Scalars["String"]>>>;
  imoNumber?: Maybe<Array<Maybe<Scalars["String"]>>>;
  matchName?: Maybe<Scalars["String"]>;
  matchCallSign?: Maybe<Scalars["String"]>;
  limit?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
};

export type QueryHeAisGeoCursorArgs = {
  mmsi?: Maybe<Scalars["String"]>;
  startTime?: Maybe<Scalars["Instant"]>;
  endTime?: Maybe<Scalars["Instant"]>;
  limit?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
};

export type QueryHeVhfDscGeoCursorArgs = {
  mmsi?: Maybe<Scalars["String"]>;
  startTime?: Maybe<Scalars["Instant"]>;
  endTime?: Maybe<Scalars["Instant"]>;
  limit?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
};

export type QueryAisClassACursorArgs = {
  mmsi: Scalars["String"];
  startTime: Scalars["Instant"];
  endTime: Scalars["Instant"];
  limit?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
};

export type QueryAisClassBCursorArgs = {
  mmsi: Scalars["String"];
  startTime: Scalars["Instant"];
  endTime: Scalars["Instant"];
  limit?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
};

export type QueryAisVoyageCursorArgs = {
  mmsi: Scalars["String"];
  startTime: Scalars["Instant"];
  endTime: Scalars["Instant"];
  limit?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
};

export type Vessel = {
  __typename?: "Vessel";
  id?: Maybe<Scalars["ID"]>;
  imoNumber: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  mmsi?: Maybe<Scalars["String"]>;
  callSign?: Maybe<Scalars["String"]>;
  flagName?: Maybe<Scalars["String"]>;
  officialNumber?: Maybe<Scalars["String"]>;
  registeredOwner?: Maybe<Scalars["String"]>;
  registeredOwnerCountryOfDomicile?: Maybe<Scalars["String"]>;
  operator?: Maybe<Scalars["String"]>;
  operatorCountryOfControl?: Maybe<Scalars["String"]>;
  manager?: Maybe<Scalars["String"]>;
  managerCountryOfControl?: Maybe<Scalars["String"]>;
  dateOfBuild?: Maybe<Scalars["Instant"]>;
  countryOfBuild?: Maybe<Scalars["String"]>;
  shipTypeLevel2?: Maybe<Scalars["String"]>;
  shipTypeLevel3?: Maybe<Scalars["String"]>;
  grossTonnage?: Maybe<Scalars["Int"]>;
  shipSanctionedEu?: Maybe<Scalars["Boolean"]>;
  shipSanctionedOfac?: Maybe<Scalars["Boolean"]>;
  shipSanctionedOfacNonSdn?: Maybe<Scalars["Boolean"]>;
  shipSanctionedUn?: Maybe<Scalars["Boolean"]>;
  ownerSanctionedEu?: Maybe<Scalars["Boolean"]>;
  ownerSanctionedOfac?: Maybe<Scalars["Boolean"]>;
  ownerSanctionedBes?: Maybe<Scalars["Boolean"]>;
  ownerSanctionedUn?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["Instant"]>;
  updatedAt?: Maybe<Scalars["Instant"]>;
  vhfDscCount?: Maybe<Scalars["Int"]>;
  vhfDscLastGeoTime?: Maybe<Scalars["Instant"]>;
  vhfDscLastGeoLocation?: Maybe<Scalars["GeoJson"]>;
  darkshipCount?: Maybe<Scalars["Int"]>;
  rndvzCount?: Maybe<Scalars["Int"]>;
  aisLastReported?: ClassAPosition;
};

export type VesselCursorPage = {
  __typename?: "VesselCursorPage";
  content?: Maybe<Array<Maybe<Vessel>>>;
  pageInfo: PageInfo;
};

export type VhfEllipses = {
  __typename?: "VhfEllipses";
  id?: Maybe<Scalars["ID"]>;
  passGroupId?: Maybe<Scalars["String"]>;
  downlinkedAt?: Maybe<Scalars["Instant"]>;
  createdAt?: Maybe<Scalars["Instant"]>;
  soi?: Maybe<Scalars["String"]>;
  constellation?: Maybe<Scalars["String"]>;
  receivedAt?: Maybe<Scalars["Instant"]>;
  semiMajor?: Maybe<Scalars["Float"]>;
  semiMinor?: Maybe<Scalars["Float"]>;
  orientation?: Maybe<Scalars["Float"]>;
  detectedLocation?: Maybe<Scalars["GeoJson"]>;
  frequency?: Maybe<Scalars["String"]>;
  numBursts?: Maybe<Scalars["Int"]>;
  channel?: Maybe<Scalars["Int"]>;
  mmsi?: Maybe<Scalars["String"]>;
  mmsiConfidence?: Maybe<Scalars["String"]>;
  mmsiConfidencePattern?: Maybe<Scalars["String"]>;
  ellipse?: Maybe<Scalars["GeoJson"]>;
  ellipseArea?: Maybe<Scalars["Float"]>;
  version?: Maybe<Scalars["String"]>;
};

export type Voyage = {
  __typename?: "Voyage";
  mmsi: Scalars["String"];
  ts: Scalars["Instant"];
  aisVersion?: Maybe<Scalars["Int"]>;
  callsign?: Maybe<Scalars["String"]>;
  destination?: Maybe<Scalars["String"]>;
  dimBow?: Maybe<Scalars["Int"]>;
  dimPort?: Maybe<Scalars["Int"]>;
  dimStarboard?: Maybe<Scalars["Int"]>;
  dimStern?: Maybe<Scalars["Int"]>;
  draught?: Maybe<Scalars["Float"]>;
  dte?: Maybe<Scalars["Int"]>;
  eta?: Maybe<Scalars["Instant"]>;
  imo?: Maybe<Scalars["Int"]>;
  msgType?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  posDev?: Maybe<Scalars["Int"]>;
  posDevString?: Maybe<Scalars["String"]>;
  repeatIndicator?: Maybe<Scalars["Int"]>;
  shipType?: Maybe<Scalars["Int"]>;
  shipTypeString?: Maybe<Scalars["String"]>;
  spare?: Maybe<Scalars["Int"]>;
};

export type VesselRegistryFieldsFragment = (
  {__typename?: "Vessel"}
  & Pick<Vessel, "id" | "imoNumber" | "name" | "status" | "mmsi" | "callSign" | "flagName" | "officialNumber" | "registeredOwner" | "registeredOwnerCountryOfDomicile" | "operator" | "operatorCountryOfControl" | "manager" | "managerCountryOfControl" | "dateOfBuild" | "countryOfBuild" | "shipTypeLevel2" | "shipTypeLevel3" | "grossTonnage" | "shipSanctionedEu" | "shipSanctionedOfac" | "shipSanctionedOfacNonSdn" | "shipSanctionedUn" | "ownerSanctionedEu" | "ownerSanctionedOfac" | "ownerSanctionedBes" | "ownerSanctionedUn" | "createdAt" | "updatedAt" | "vhfDscCount" | "vhfDscLastGeoTime" | "vhfDscLastGeoLocation" | "darkshipCount" | "rndvzCount">
);

export type VesselCursorQueryVariables = Exact<{
  mmsi: Array<Maybe<Scalars["String"]>>;
}>;

export type VesselCursorQuery = (
  {__typename?: "Query"}
  & {vesselCursor?: Maybe<(
    {__typename?: "VesselCursorPage"}
    & {content?: Maybe<Array<Maybe<(
      {__typename?: "Vessel"}
      & VesselRegistryFieldsFragment
    )>>>}
  )>}
);

export type VesselSearchQueryVariables = Exact<{
  name?: Maybe<Scalars["String"]>;
  imoNumber?: Maybe<Array<Maybe<Scalars["String"]>>>;
  mmsi?: Maybe<Array<Maybe<Scalars["String"]>>>;
}>;

export type VesselSearchQuery = (
  {__typename?: "Query"}
  & {vesselCursor?: Maybe<(
    {__typename?: "VesselCursorPage"}
    & {content?: Maybe<Array<Maybe<(
      {__typename?: "Vessel"}
      & VesselRegistryFieldsFragment
    )>>>}
  )>}
);

export const VesselRegistryFieldsFragmentDoc = gql`
    fragment vrFields on Vessel {
      id
      imoNumber
      name
      status
      mmsi
      callSign
      flagName
      officialNumber
      registeredOwner
      registeredOwnerCountryOfDomicile
      operator
      operatorCountryOfControl
      manager
      managerCountryOfControl
      dateOfBuild
      countryOfBuild
      shipTypeLevel2
      shipTypeLevel3
      grossTonnage
      shipSanctionedEu
      shipSanctionedOfac
      shipSanctionedOfacNonSdn
      shipSanctionedUn
      ownerSanctionedEu
      ownerSanctionedOfac
      ownerSanctionedBes
      ownerSanctionedUn
      createdAt
      updatedAt
      vhfDscCount
      vhfDscLastGeoTime
      vhfDscLastGeoLocation
      darkshipCount
      rndvzCount
      aisLastReported {
        mmsi
        ts
        pt
      }
    }
`;

export const VesselCursorDocument = gql`
    query VesselCursor($mmsi: [String]!) {
  vesselCursor(mmsi: $mmsi, limit: 2) {
    content {
      ...vrFields
    }
  }
}
${VesselRegistryFieldsFragmentDoc}`;

/**
 * __useVesselCursorQuery__
 *
 * To run a query within a React component, call `useVesselCursorQuery` and pass it any options that fit your needs.
 * When your component renders, `useVesselCursorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVesselCursorQuery({
 *   variables: {
 *      mmsi: // value for 'mmsi'
 *   },
 * });
 */
export function useVesselCursorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VesselCursorQuery, VesselCursorQueryVariables>) {
        return ApolloReactHooks.useQuery<VesselCursorQuery, VesselCursorQueryVariables>(VesselCursorDocument, baseOptions);
      }
export function useVesselCursorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VesselCursorQuery, VesselCursorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VesselCursorQuery, VesselCursorQueryVariables>(VesselCursorDocument, baseOptions);
        }
export type VesselCursorQueryHookResult = ReturnType<typeof useVesselCursorQuery>;
export type VesselCursorLazyQueryHookResult = ReturnType<typeof useVesselCursorLazyQuery>;
export type VesselCursorQueryResult = ApolloReactCommon.QueryResult<VesselCursorQuery, VesselCursorQueryVariables>;
export const VesselSearchDocument = gql`
    query VesselSearch($name: String, $imoNumber: [String], $mmsi: [String]) {
  vesselCursor(matchName: $name, imoNumber: $imoNumber, mmsi: $mmsi) {
    content {
      ...vrFields
    }
  }
}
    ${VesselRegistryFieldsFragmentDoc}`;

/**
 * __useVesselSearchQuery__
 *
 * To run a query within a React component, call `useVesselSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useVesselSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVesselSearchQuery({
 *   variables: {
 *      name: // value for 'name'
 *      imoNumber: // value for 'imoNumber'
 *      mmsi: // value for 'mmsi'
 *   },
 * });
 */
export function useVesselSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VesselSearchQuery, VesselSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<VesselSearchQuery, VesselSearchQueryVariables>(VesselSearchDocument, baseOptions);
      }
export function useVesselSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VesselSearchQuery, VesselSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VesselSearchQuery, VesselSearchQueryVariables>(VesselSearchDocument, baseOptions);
        }
export type VesselSearchQueryHookResult = ReturnType<typeof useVesselSearchQuery>;
export type VesselSearchLazyQueryHookResult = ReturnType<typeof useVesselSearchLazyQuery>;
export type VesselSearchQueryResult = ApolloReactCommon.QueryResult<VesselSearchQuery, VesselSearchQueryVariables>;
