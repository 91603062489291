import * as React from "react";
import "../../styles/VesselRegistry.scss";
import {useDispatch} from "react-redux";
import exitIcon from "../../styles/icons/exit_white.svg";
import searchGrayIcon from "../../styles/icons/search_dark_gray.svg";
import searchWhiteIcon from "../../styles/icons/search_white.svg";
import downIcon from "../../styles/icons/down_arrow_widget.svg";
import backIcon from "../../styles/icons/floating_hide_white.svg";
import upIcon from "../../styles/icons/up_arrow_widget.svg";
import VesselSearchResults from "./VesselSearchResults";
import VesselRegistryDetails from "./VesselRegistryDetails";
import {Vessel} from "../../generated/graphql";
import {setVesselSearchMmsis} from "../../actions/DeckMapActions";
import {clearLastKnownTrack} from "../../actions/TrackActions";

import {fireToast} from "../../actions/ToastActions";

interface SelectedVessel {
    vessel: Vessel | null;
    autoSelect: boolean;
}

interface Props {
    uiPanelsVisible: boolean;
    vesselSearchValue: {timestamp: Date | null; searchValue: string;};
}

const VesselSearch: React.FunctionComponent<Props> = (props: Props) => {
    const [searchText, setSearchText] = React.useState<string>("");
    const [showContent, setShowContent] = React.useState<boolean>(false);
    const [searchObject, setSearchObject] = React.useState<object>({});
    const [selectedVessel, setSelectedVessel] = React.useState<SelectedVessel>({vessel: null, autoSelect: true});
    const [hidingPanel, setHidingPanel] = React.useState<boolean>(false);

    const dispatch = useDispatch();
    const handleSettingMmsis = (mmsis: string[]) => dispatch(setVesselSearchMmsis(mmsis));
    const handleFiringToast = (text: string, header: string, type: string) => dispatch(fireToast(text, header, type));

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.currentTarget.value);
    };

    const handleSubmitClick = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        submitSearch(searchText);
        dispatch(clearLastKnownTrack());
    };

    const submitSearch = React.useCallback((searchValue: string) => {
        // Creating the search object that will be passed to the query.
        if (/[A-Za-z]/.test(searchValue)) {
            setSearchObject({name: searchValue});
        } else if (searchValue.length === 9) {
            setSearchObject({mmsi: searchValue});
        } else {
            setSearchObject({imoNumber: searchValue});
        }

        // Clear selected vessel if any. Turn hiding off if on.
        setSelectedVessel({vessel: null, autoSelect: true});
        setHidingPanel(false);
        setShowContent(true);
    }, []);

    const updateSelectedVessel = (vessel: Vessel | null) => { setSelectedVessel({vessel, autoSelect: false}); };

    const closePanel = () => {
        // Reset everything.
        setShowContent(false);
        setSearchText("");
        setHidingPanel(false);
        setSelectedVessel({vessel: null, autoSelect: true});
        dispatch(clearLastKnownTrack());
        dispatch(setVesselSearchMmsis([]));
    };

    const backToResults = () => {
        setSelectedVessel({vessel: null, autoSelect: false});
        dispatch(clearLastKnownTrack());
    };

    const formatDate = (date: string) => {
        const newDate = new Date(date);
        return newDate.toLocaleString("default", {hour12: false, timeZone: "UTC"}).split(",");
    };

    React.useEffect(() => {
      if (props.vesselSearchValue.searchValue) {
        setSearchText(props.vesselSearchValue.searchValue);
        submitSearch(props.vesselSearchValue.searchValue);
      }
      if (!props.vesselSearchValue.timestamp) {
        closePanel();
      }
    }, [props.vesselSearchValue, submitSearch]);

    return (
      <div className={`hss-ms-vr-search-container ${props.uiPanelsVisible ? "" : "hidden"}  ${!hidingPanel ? "" : "minimized"}`}>
        {showContent && (
        <div className="hss-ms-vr-search-results-container">
          <div className={`hss-ms-vr-search-results-nav ${!selectedVessel.vessel ? "" : "selected"}`}>
            <button
              type="button"
              className="hss-icon-button vr-back-icon-button hss-ms-bbg"
              onClick={backToResults}
            >
              <img alt="Back to vessel search results" src={backIcon} />
            </button>

            <div className="hss-ms-vr-search-results-nav-exit-min-container">
              <button
                type="button"
                className="hss-icon-button hss-ms-bbg"
                onClick={closePanel}
              >
                <img alt="Exit vessel search" src={exitIcon} />
              </button>

              <button
                type="button"
                className="hss-icon-button hss-ms-bbg"
                onClick={() => setHidingPanel(!hidingPanel)}
              >
                <img alt="Minimize vessel search" src={hidingPanel ? upIcon : downIcon} />
              </button>
            </div>
          </div>
          <div className="hss-ms-vr-search-results-content-container">
            {selectedVessel.vessel ? (
              <VesselRegistryDetails
                vesselInfo={selectedVessel.vessel}
                formatDate={formatDate}
              />
              ) : (
                <VesselSearchResults
                  searchParams={searchObject}
                  searchString={searchText}
                  selectVessel={updateSelectedVessel}
                  autoSelect={selectedVessel.autoSelect}
                  formatDate={formatDate}
                  fireToast={handleFiringToast}
                  handleSettingMmsis={handleSettingMmsis}
                />
                )}
          </div>
        </div>
        )}
        <div className="hss-ms-input-container">
          <form onSubmit={handleSubmitClick}>
            <input
              className="hss-ms-input hss-ms-bbg hss-ms-vr-input"
              type="text"
              placeholder="Vessel search"
              onChange={(e) => handleInputChange(e)}
              onKeyDown={(e) => e.stopPropagation()}
              onKeyUp={(e) => e.stopPropagation()}
              onKeyPress={(e) => e.stopPropagation()}
              value={searchText}
            />
          </form>
          <button type="submit" onClick={handleSubmitClick} className="hss-icon-button hss-ms-bbg hss-no-selection">
            <img className="hss-icon-img" alt="Submit vessel search" src={searchText ? searchWhiteIcon : searchGrayIcon} />
          </button>
        </div>
      </div>
    );
};

export default VesselSearch;
