import React from "react";
import {useSelector} from "react-redux";
import {MapStatistics} from "../@types/DeckMapTypes";
import {IApplicationState} from "../Store";
import {numFormatter} from "./MapMainPanel/FeatureMetadataContainer";

export interface SignalCountLineProps {
    total: number;
    signal: MapStatistics;
}

const SignalCountLine: React.FunctionComponent<SignalCountLineProps> = ({total, signal}) => {
    const darkRfEnabled = useSelector((state: IApplicationState) => state.deck.darkRfEnabled);

    const getBarGraphLength = (signalCount: number, totalCount: number): number => {
        if (signalCount) {
            const percentage = (signalCount / totalCount) * 100;
            // Handles edge case when the percentage is less then 0
            return Math.floor(percentage) > 0 ? Math.floor(percentage) : 1;
        }
        return signalCount;
    };

    const getDarkRfWidth = (darkRfCount: number): number => {
        if (!darkRfCount) return 0;
        const percentage = (darkRfCount / total) * 100;
        return Math.floor(percentage);
    };

    return (
      <>
        <li className="signal-line">
          <div className="hss-ms-object-details-signal">
            <span>{signal.signalName}</span>
            <span>{numFormatter(signal.count)}</span>
          </div>
          <div className="hss-ms-object-details-signal-graph">
            <div
              className="hss-ms-object-details-signal-graph-bar"
              style={{width: `${getBarGraphLength(signal.count, total)}%`}}
            />
            {darkRfEnabled && (
              <div
                data-testid="signal-line-dark-rf"
                className="signal-line-dark-rf"
                style={{width: `${getDarkRfWidth(signal.darkRfCount)}%`}}
              />
              )}
          </div>
        </li>
      </>
    );
};

export default SignalCountLine;
