import * as React from "react";
import Toast from "./Toast";
import {IToast} from "../@types/ToastType";
import "../styles/Toast.scss";

type ToastsProps = StateProps & DispatchProps;

export interface StateProps {
    toasts: IToast[];
}

export interface DispatchProps {
    hideToast: (toast: IToast) => any;
}

const Toasts = ({toasts, hideToast}: ToastsProps) => (
  <div className="toast-container">
    { toasts.map((toast: IToast, index) =>
      // eslint-disable-next-line react/no-array-index-key
      <Toast key={index} toast={toast} hideToast={() => hideToast(toast)} />,
            ) }
  </div>
    );

export default Toasts;
