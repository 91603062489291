import * as React from "react";
import axios from "axios";
import {useDispatch} from "react-redux";
import {fireToast} from "actions/ToastActions";
import Config from "../../Config";
import {version as missionSpaceVersion} from "../../../package.json";

function convertTime(dateTime: string) {
  return new Date(dateTime).toLocaleTimeString("UTC");
}

interface AccountSettingsProps {
    apiKey: string
}

type LastLogin = {loginTime: string; loginIP: string};

const AccountSettings: React.FunctionComponent<AccountSettingsProps> = ({apiKey}) => {
    const [lastLogin, setLastLogin] = React.useState<LastLogin | null>(null);
    const resetPWLink = `${Config.keycloak.url}realms/${Config.keycloak.realm}/account/password`;
    const dispatch = useDispatch();

    React.useEffect(() => {
        const fetchLastLogin = async () => {
            try {
              const lastLoginResp = await axios.get(`${Config.apiUrl}/users/current/lastlogin`);
              setLastLogin(lastLoginResp.data);
            } catch (e) {
                console.error(e);
            }
        };

        fetchLastLogin();
    }, [setLastLogin]);

    const resetSession = async (e: React.MouseEvent) => {
        e.preventDefault();

        try {
            const resp = await fetch(`${Config.apiUrl}/state/map`, {
                method: "DELETE",
                headers: {Authorization: `Bearer ${apiKey}`},
            });

            if (resp.ok) {
                dispatch(fireToast("Your account's session has been reset successfully.", "Session reset successful.", "secondary"));
            } else {
                throw new Error("Unable to reset session.");
            }
        } catch (e) {
            dispatch(fireToast(
                "Failed to reset your session, please check your network or contact your system administrator.",
                "Failed to reset session",
                "error",
            ));
        }
    };

    return (
      <div className="hss-ms-flex-col">
        <div className="hss-ms-h2">Account</div>
        <div className="hss-ms-hr-div" />
        <form className="hss-ms-account-form">
          <label className="hss-ms-bold">Actions</label>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="hss-ms-button hss-ms-text-button-standalone"
            href={resetPWLink}
          >
            Reset password
          </a>
          <input
            type="submit"
            className="hss-ms-button hss-ms-text-button-standalone"
            value="Reset session"
            onClick={resetSession}
          />
        </form>

        {lastLogin && (
          <div className="last-login">
            <p className="hss-ms-bold">Last login</p>
            <p style={{paddingTop: ".75rem"}}>
              {`${convertTime(lastLogin.loginTime)} from ${lastLogin.loginIP}`}
            </p>
          </div>
        )}

        <div className="ms-version-container">
          <p>Version</p>
          <p>{missionSpaceVersion}</p>
        </div>
      </div>
    );
};

export default AccountSettings;
