import {IToast} from "../@types/ToastType";

export type SHOW_TOAST = "SHOW_TOAST";
export const SHOW_TOAST: SHOW_TOAST = "SHOW_TOAST";
export type HIDE_TOAST = "HIDE_TOAST";
export const HIDE_TOAST: HIDE_TOAST = "HIDE_TOAST";

export type ToastAction = {
    type: SHOW_TOAST | HIDE_TOAST,
    toast: IToast
};

export const showToast = (text: string, header: string, className: string, timeout: number) => ({
    type: SHOW_TOAST,
    toast: {
        timestamp: Date.now(),
        text,
        header,
        className,
        timeout,
    },
});

export const hideToast = (toast: IToast): ToastAction => ({
    type: HIDE_TOAST,
    toast,
});

export const showConfirmationToast = (text: string, header: string, className: string, timeout: number, confirmationFunction: Function) => ({
    type: SHOW_TOAST,
    toast: {
        text,
        header,
        className,
        timeout,
        confirmationFunction,
    },
});

// Generic Toast Notification
export const fireToast = (text: string, header: string, type: string) => (dispatch: any): void => {
  let timeoutDuration = 5000;
  let toastAction = showToast(text, header, "success", timeoutDuration);
  if (type === "error") {
    toastAction = showToast(text, header, "error", timeoutDuration);
  }
  if (type === "secondary") {
    timeoutDuration = 3000;
    toastAction = showToast(text, header, "secondary", timeoutDuration);
  }
  dispatch(toastAction);
  setTimeout(() => dispatch(hideToast(toastAction.toast)), (timeoutDuration + 200));
};

export const fireConfirmationToast = (text: string, header: string, confirmFunction: Function) => (dispatch: any): void => {
    const toastAction = showConfirmationToast(text, header, "success", 0, confirmFunction);
    dispatch(toastAction);
};
