import axios from "axios";
import {print} from "graphql";
import {store} from "../Store";
import {fireToast} from "./ToastActions";
import Config from "../Config";
import {DeckMapState} from "../@types/DeckMapTypes";
import {EDIT_SNAPSHOT} from "../components/Snapshots/query";
import {updateCurrentSnapshot, formatData} from "./PlaybookActions";

import {PlaybookState} from "../@types/PlaybookTypes";

export function saveState(state: PlaybookState) {
    const {openPlaybooks} = state;

    axios.post(`${Config.apiUrl}/state/map`, {state: openPlaybooks})
    .then((resp) => {
        if (resp.status >= 400) throw new Error("Unable to save session.");
    }).catch((err) => {
        console.error(err);
        store.dispatch<any>(fireToast("Failed to save session, check your network or contact your system administrator.",
            "Failed to save", "error"));
    });
}

// For some reason this throws an error when in the PlaybookActions file.
export function updateSnapshot(currentState: DeckMapState) {
    if (currentState.currentSnapshot && currentState.currentSnapshot.active) {
        const {id, name, playbookId} = currentState.currentSnapshot;
        const {formattedSnapshot, mapCenter, mapConfiguration, mapFilters} = formatData(currentState);
        axios.post(`${Config.apiUrl}/graphql`, {
            query: print(EDIT_SNAPSHOT),
            variables: {
                snapShot: {
                    ...formattedSnapshot, id, name, playbookId,
                },
                mapCenter,
                mapConfiguration,
                mapFilters,
            },
        }).then((resp) => {
            if (resp.data.data.editSnapshot) {
                store.dispatch<any>(updateCurrentSnapshot(resp.data.data.editSnapshot));
            } else {
                throw new Error("Cannot update snapshot fields");
            }
        }).catch((err) => console.error(err));
    }
}
