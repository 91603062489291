import * as React from "react";
import {useSelector} from "react-redux";
import {useTreatments} from "@splitsoftware/splitio-react";
import {RFGeoLayer} from "../../../@types/Layers";
import {IApplicationState} from "../../../Store";
import {ICON_MAP} from "../../iconMap";
import {IconMapTypes} from "../../../@types/PlaybookTypes";
import {UpdateFormFunction} from "./AlertRuleReducer";

export interface Props {
    alertRuleFormDispatch: UpdateFormFunction;
    selectedSignals: string[];
    onlyIdentifiableSois?: boolean; // filters displayed list to only show SOIs that might have an ID
}

const SignalSection: React.FunctionComponent<Props> = (props: Props) => {
    const layers = useSelector((state: IApplicationState) => state.deck.layers);
    const iconMapStyle = useSelector((state: IApplicationState) => state.deck.iconMapType);
    const iconBackground = iconMapStyle === IconMapTypes.SIMPLE ? 'url("/map_symbols/1x/iconmap1x.png")' : 'url("/map_symbols/1x/new_iconmap_1x.png")';
    const treatments = useTreatments(["gps_interference"]);

    const handleSelection = (e: React.ChangeEvent<HTMLInputElement>): void => {
        props.alertRuleFormDispatch({
            type: "selectedSignals",
            value: e.currentTarget.value,
            selected: e.currentTarget.checked,
        });
    };

    function getLayer(layer: RFGeoLayer) {
        if (layer.isSeaker) return null;
        // Removing one of the vhf selections since there is only one VHF signal in the backend.
        if (props.onlyIdentifiableSois && !layer.hasId) return null;
        if (!props.onlyIdentifiableSois && layer.name === "vhf_identified") {
            return null;
        }
        const icon = ICON_MAP[layer.name as keyof typeof ICON_MAP];
        const selected = props.selectedSignals.includes(layer.alertValue ? layer.alertValue : "");
        const renderSignal = layer.splitName ? treatments[layer.splitName].treatment : "on";

        if (renderSignal !== "off") {
            Object.entries(icon).filter(([key, value]) => key !== "gps_interference");

            return (
              <div className="signal-section-option" key={layer.name}>
                <div className="signal-section-label">
                  <div
                    className="signal-section-icon"
                    style={{
                                backgroundImage: iconBackground,
                                backgroundPosition: `-${icon.x}px -${icon.y}px`,
                                height: icon.height,
                                width: icon.width,
                            }}
                  />
                  <p>{layer.label}</p>
                </div>
                <input
                  className="hss-checkbox"
                  type="checkbox"
                  value={layer.alertValue}
                  onChange={handleSelection}
                  checked={selected}
                />
              </div>
            );
        }
    }

    return (
      <div className="signal-section-container">
        <h3>Signals</h3>
        <div className="signal-section-options-container">
          {layers?.map((layer) => getLayer(layer))}
        </div>
      </div>
    );
};

export default SignalSection;
