/* eslint-disable react/prop-types */
import * as React from "react";
import {Vessel, Maybe} from "../../generated/graphql";
import "../../styles/VesselRegistry.scss";
import Ship from "../../styles/icons/circle.svg"; // using a circle svg until I get the ship svg
import ValueDisplay from "./ValueDisplay";
import FlagGraphics from "../FlagGraphics";

interface Props {
    vesselInfo: Maybe<Vessel> | null | undefined;
}

const VesselRegistryCompact: React.FunctionComponent<Props> = ({vesselInfo}) => {
    if (vesselInfo) {
        return (
          <div className="vessel-registry-container" data-testid="vessel-registry-container">
            {/* Hiding Vessel icon until we we get real Vessel icons */}
            <div style={{visibility: "hidden"}} className="vessel-ship-svg"><img alt="Vessel type" src={Ship} /></div>
            <div className="vessel-registry-preview-col">
              <ValueDisplay header="Vessel name" value={vesselInfo.name} />
              <ValueDisplay header="Owner" value={vesselInfo.registeredOwner} />
            </div>
            <div className="vessel-registry-preview-col">
              <ValueDisplay header="Vessel type" value={vesselInfo.shipTypeLevel2} />
              <ValueDisplay header="Operator" value={vesselInfo.operator} />
            </div>
            <div className="vessel-registry-preview-col vessel-registry-preview-small-col">
              <ValueDisplay header="Call sign" value={vesselInfo.callSign} />
              <div className="vr-value-display">
                <h3 data-testid="vr-value-header">Flag</h3>
                <FlagGraphics mmsi={vesselInfo.mmsi} />
              </div>
            </div>
          </div>
        );
    }

    return (
      <div className="vessel-registry-container" data-testid="vessel-registry-container">
        <p className="no-vessel-preview-content">Vessel preview data not available</p>
      </div>
    );
};

export default VesselRegistryCompact;
