import gql from "graphql-tag";

export const QUERY_VESSEL_CURSOR = gql`
    fragment vesselRegistryFields on Vessel {
            id
            imoNumber
            name
            status
            mmsi
            callSign
            flagName
            officialNumber
            registeredOwner
            registeredOwnerCountryOfDomicile
            operator
            operatorCountryOfControl
            manager
            managerCountryOfControl
            dateOfBuild
            countryOfBuild
            shipTypeLevel2
            shipTypeLevel3
            grossTonnage
            shipSanctionedEu
            shipSanctionedOfac
            shipSanctionedOfacNonSdn
            shipSanctionedUn
            ownerSanctionedEu
            ownerSanctionedOfac
            ownerSanctionedBes
            ownerSanctionedUn
            createdAt
            updatedAt
    }
    
    query VesselCursor($mmsi: [String]!) {
        vesselCursor(mmsi: $mmsi, limit: 1) {
            content {
                ...vesselRegistryFields  
            }
        }
    }
    
    query VesselSearch($name: String, $imoNumber: [String], $mmsi: [String]){
        vesselCursor(matchName: $name, imoNumber: $imoNumber, mmsi: $mmsi){
            content {
                ...vesselRegistryFields
            }
        }
    }
`;

// Slower version of vessel cursor that deals with seaker counts.  Only call if those counts are needed
export const HE_QUERY_VESSEL_CURSOR = gql`    
    query heVesselCursor($mmsi: [String]!) {
        heVesselCursor(mmsi: $mmsi, limit: 1) {
            content {
                vhfDscCount
                vhfDscLastGeoTime
                vhfDscLastGeoLocation
                darkshipCount
                rndvzCount
            }
        }
    }

`;
export const VESSEL_MMSI_LOOKUP = gql`
    query VesselSearch($name: String, $imoNumber: [String]){
    vesselCursor(matchName: $name, imoNumber: $imoNumber) {
        content {
            mmsi
        }
    }
}`;

export const LAST_KNOWN_SIGHTINGS = gql`
    query lastKnownSightings($mmsis:[String!]!, $ts: Instant){
        lastKnownSightings(mmsis: $mmsis ts: $ts){
            mmsi
            ais{
                location
                ts
                positionType
            }
            vhf{
                location
                ts
                positionType
            }
            darkship{
                location
                ts
                positionType
            }
            rendezvous{
                location
                ts
                positionType
            }
        }
    }
`;

export const GEO_VESSEL_ASSOCIATION = gql`
    query geoVesselAssociation($geoId: ID!, $limit: Int = 50, $cursor: String){
        geoVesselAssociationCursor(geoId: $geoId, limit: $limit, cursor: $cursor) {
            geoId
            content {
                id
                mmsi
                estimatedLocation
                vesselPathInterpolationFraction
                vesselGeoBearing
                vesselPath
                vesselPathStartTime
                vesselPathEndTime
                vesselPathHistory
                normalizedDistance
                score
                probability
                openSetProbability
            }
            pageInfo {
                hasNext
                nextCursor
            }
        }
    }
`;
