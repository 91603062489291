import React, {CSSProperties, useEffect} from "react";
import {Tooltip} from "./Tooltip";
import "../styles/ActionButton.scss";
import "../styles/ActionButtonIcons.scss";

export interface ActionButtonProps {
  onClick: () => void;
  buttonSize?: number;
  mode?: boolean; // true for "active state" like when a drawing tool is in use
  style?: CSSProperties;
  className: string;
  tooltip?: string;
  hotkey?: string;
  fireToast?: Function;
  toastContent?: string;
  disabled?: boolean;
}

const ActionButton: React.FunctionComponent<ActionButtonProps> = (props: ActionButtonProps) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (props.hotkey && event.key.toLowerCase() === props.hotkey.toLowerCase()) {
        props.onClick();
      }
    };
    if (props.hotkey) {
      window.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [props]);

  const handleClick = () => {
    if (!props.disabled) {
      if (props.fireToast) {
        props.fireToast(props.toastContent, "", "secondary");
      }
      props.onClick();
    }
  };

  return (
    <div className="ActionButtonContainer hss-no-selection">
      <Tooltip content={props.tooltip} enable={!props.disabled}>
        <button
          type="button"
          className={`ActionButton ${props.mode ? "active" : "inactive"} ${props.className}`}
          disabled={props.disabled}
          style={props.style}
          onClick={handleClick}
          data-testid={`actionButton ${props.className}`}
        >
          <div className="icon-bg">
            <div className="icon-hotkey">{props.hotkey}</div>
          </div>
        </button>
      </Tooltip>
    </div>
  );
};

export default ActionButton;
